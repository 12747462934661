import { Component, Directive, ElementRef, OnInit, Renderer2, ViewChild, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogosService } from '../catalogos.service';
import { DataService } from '../login/data.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private servicio: CatalogosService, private _router: Router,
    private ruta: ActivatedRoute, private data: DataService) {
  }

  message: string;
  flag: boolean;

  // Tamaño pantalla dimensiones
  ancho = 0;
  alto = 0;
  botones = 0;

  // NAVBAR
  collapsed = true;
  mobile = false;

  // dia festivo 1-normal 
  festivo = 1;

  // Cuentas
  logged = true;
  empresa = sessionStorage.getItem("empresa");
  timbres = sessionStorage.getItem("timbres_restantes");

  // Licencia y Addendas
  licencia: string;
  licencia_get: string;

  block_addendas: boolean;


  do1() {
    this.checkData();
    event.stopPropagation();
  }

  closeOffcanvas(event) {
    if (event.target.matches('a')) {
      // console.log('user clicked on:', event.target, 'with name: ', event.target.innerHTML);
    }
  }

  cliente = '';
  url_logo = 'TIMBRANET-03_EDIT.png'

  obj_usuario = {
    id_usuario: ''
  }
  array_addendas: any = []

  check_addendas() {
    this.obj_usuario.id_usuario = sessionStorage.getItem('no_cliente')
    this.servicio.addendas_usuario(this.obj_usuario).subscribe(res => {
      this.array_addendas = res;
      console.log(this.array_addendas);
      if (this.array_addendas.length == 0) {
        this.block_addendas = true
      } else {
        this.block_addendas = false
      }
    })
  }

  check_festivo() {
    const festivo = sessionStorage.getItem('festivo')
    console.log(festivo);

    if (festivo == '1') {
      this.url_logo = 'TIMBRANET-03_EDIT.png'
    } else if (festivo == '2') {
      this.url_logo = 'TIMBRANET-03_EDIT_MX.png'
    } else if (festivo == '3') {
      this.url_logo = 'TIMBRANET-03_EDIT_NV.png'
    } else if (festivo == '4') {
      this.url_logo = 'TIMBRANET-03_EDIT_HW.png'
    }

  }

  ngOnInit(): void {

    this.check_festivo();

    this.data.currentMessage.subscribe(message => this.licencia = message);
    this.data.currentFlag.subscribe(arrayflag => this.block_addendas = arrayflag);

    this.licencia_get = sessionStorage.getItem('licencia')
    this.data.changeMessage(this.licencia_get)

    this.check_addendas();
    this.resolucion();

  }


  // ------------------- VERIFICACION DE DATOS PRE TIMBRADO -------------------

  obj_user = {

    // DATOS CERTIFICADO
    certificado: '',
    NoCertificado: '',
    nombre_cer: '',
    cliente_key: '',

    // EMPRESA
    empresa: '',
    rfc: '',
    calle: '',
    cp: '',
    regimenFiscal: '',
  }


  checkData() {

    // DATOS CERTIFICADO
    this.obj_user.certificado = sessionStorage.getItem('certificado')
    this.obj_user.NoCertificado = sessionStorage.getItem('noCertificado')
    this.obj_user.nombre_cer = sessionStorage.getItem('nombre_cer')
    this.obj_user.cliente_key = sessionStorage.getItem('cliente_key')

    // EMPRESA
    this.obj_user.empresa = sessionStorage.getItem('empresa')
    this.obj_user.rfc = sessionStorage.getItem('rfc')
    this.obj_user.calle = sessionStorage.getItem('calle')
    this.obj_user.cp = sessionStorage.getItem('cp')
    this.obj_user.regimenFiscal = sessionStorage.getItem('regimen')

    // CHECKS DE DATOS PERSONALES 
    if (this.obj_user.certificado == '' || this.obj_user.certificado == null ||
      this.obj_user.NoCertificado == '' || this.obj_user.NoCertificado == null ||
      this.obj_user.nombre_cer == '' || this.obj_user.nombre_cer == null
    ) {
      console.log(this.obj_user);
      Swal.fire({
        icon: "warning",
        title: "¡Advertencia!",
        text: "No existe un certificado activo, el programa puede no funcionar correctamente",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cargar Certificado",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          this._router.navigate(['/datos/userdata'], {
            relativeTo: this.ruta
          })
        }
      });
    }


    // CHECK DE CERTIFICADO
    if (this.obj_user.empresa == '' || this.obj_user.empresa == null ||
      this.obj_user.rfc == '' || this.obj_user.rfc == null ||
      this.obj_user.calle == '' || this.obj_user.calle == null ||
      this.obj_user.cp == '' || this.obj_user.cp == null ||
      this.obj_user.regimenFiscal == '' || this.obj_user.regimenFiscal == null
    ) {
      console.log(this.obj_user);
      Swal.fire({
        icon: "warning",
        title: "¡Advertencia!",
        html: `<p>Datos personales inválidos, el programa puede no funcionar correctamente</p>
        <br>

        <p style="${this.obj_user.empresa == null || this.obj_user.empresa == '' ? 'color:#dc3545' : 'color:green'}"><b>Nombre: </b>${this.obj_user.empresa}
         <i style="${this.obj_user.empresa == null || this.obj_user.empresa == '' ? 'display:none' : 'display:inline'}" class="fa fa-check-circle-o" aria-hidden="true"></i>
         <i style="${this.obj_user.empresa == null || this.obj_user.empresa == '' ? 'display:inline' : 'display:none'}" class="fa fa-times" aria-hidden="true"></i></p>

        <p style="${this.obj_user.rfc == null || this.obj_user.rfc == '' ? 'color:#dc3545' : 'color:green'}"><b>RFC: </b>${this.obj_user.rfc}
        <i style="${this.obj_user.rfc == null || this.obj_user.rfc == '' ? 'display:none' : 'display:inline'}" class="fa fa-check-circle-o" aria-hidden="true"></i>
        <i style="${this.obj_user.rfc == null || this.obj_user.rfc == '' ? 'display:inline' : 'display:none'}" class="fa fa-times" aria-hidden="true"></i></p>

        <p style="${this.obj_user.calle == null || this.obj_user.calle == '' ? 'color:#dc3545' : 'color:green'}"><b>Calle: </b>${this.obj_user.calle}
        <i style="${this.obj_user.calle == null || this.obj_user.calle == '' ? 'display:none' : 'display:inline'}" class="fa fa-check-circle-o" aria-hidden="true"></i>
        <i style="${this.obj_user.calle == null || this.obj_user.calle == '' ? 'display:inline' : 'display:none'}" class="fa fa-times" aria-hidden="true"></i></p>
        

        <p style="${this.obj_user.cp == null || this.obj_user.cp == '' ? 'color:#dc3545' : 'color:green'}"><b>C.P.: </b>${this.obj_user.cp}
        <i style="${this.obj_user.cp == null || this.obj_user.cp == '' ? 'display:none' : 'display:inline'}" class="fa fa-check-circle-o" aria-hidden="true"></i>
        <i style="${this.obj_user.cp == null || this.obj_user.cp == '' ? 'display:inline' : 'display:none'}" class="fa fa-times" aria-hidden="true"></i></p>
       
       
        <p style="${this.obj_user.regimenFiscal == null || this.obj_user.regimenFiscal == '' ? 'color:#dc3545' : 'color:green'}"><b>Régimen Fiscal: </b>${this.obj_user.regimenFiscal}
        <i style="${this.obj_user.regimenFiscal == null || this.obj_user.regimenFiscal == '' ? 'display:none' : 'display:inline'}" class="fa fa-check-circle-o" aria-hidden="true"></i>
        <i style="${this.obj_user.regimenFiscal == null || this.obj_user.regimenFiscal == '' ? 'display:inline' : 'display:none'}" class="fa fa-times" aria-hidden="true"></i></p>
        `,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Verificar Datos",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          this._router.navigate(['/datos/userdata'], {
            relativeTo: this.ruta
          })
        }
      });
    }




  }

   // ------------------------------------------------------------------------


  resetPage() {

    this._router.routeReuseStrategy.shouldReuseRoute = () => false;
    this._router.onSameUrlNavigation = 'reload';
    this._router.navigate(['./menu'], {
      relativeTo: this.ruta
    })
  }

  resolucion() {
    this.ancho = window.innerWidth;
    if (this.ancho > 1400) {
      this.botones = 1;
    } else if (this.ancho <= 1400 && this.ancho > 600) {
      this.botones = 2;
    } else if (this.ancho <= 600) {
      this.botones = 3;
    }
  }
}


