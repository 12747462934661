<!-- NO LOGEADO -->
<div *ngIf="!logged" id="fade_lg">

    <!-- FASE 1 -->

    <div class="row">
        <div class="col-sm-12 d-flex justify-content-center">
            <img src="../../assets/TIMBRANET-03_EDIT.png" class="logo" alt="">
        </div>
    </div>

    <div class="d-flex justify-content-center">
        <div class="main">

            <!-- HEADER -->
            <div class="header d-flex justify-content-center">
                <h4>LOG IN</h4>
            </div>

            <!-- CONTENIDO -->
            <div class="content">

                <div class="row d-flex justify-content-center">
                    <div class="img_login d-flex justify-content-center">
                        <img src="../../assets/FAV_TIMBRANET.png" alt="">
                        <i class="fa fa-user letter_log" aria-hidden="true"></i>
                        <!-- <h1 class="letter" style="text-align: center;">{{obj_local.nombre_local[0]}}</h1> -->
                    </div>
                </div>

                <div class="row"><br></div>

                <div *ngIf="fstStep">
                    <!-- USER -->
                    <div class="row">
                        <div class="col">
                            <div class="form-floating">
                                <input (keydown)="EnterSubmit($event)" [(ngModel)]="obj_usr.usuario" type="email"
                                    class="form-control" id="email" placeholder="">
                                <label for="email">Email</label>
                            </div>
                            <br>
                        </div>
                    </div>

                    <!-- PASSWORD -->
                    <div class="row">
                        <div class="col">
                            <div class="form-floating">
                                <input (keydown)="EnterSubmit($event)" [(ngModel)]="obj_usr.pass" type="password"
                                    class="form-control" id="pass" placeholder="">
                                <label for="pass">Contraseña</label>
                            </div>
                            <br>
                        </div>
                    </div>

                    <!-- BOTON -->
                    <div class="row d-flex justify-content-center" (keydown)="EnterSubmit($event)">
                        <button class="boton" (click)="login()">INICIAR SESIÓN</button>
                    </div>
                </div>

                <!-- FASE 2 -->
                <div *ngIf="!fstStep">

                    <!-- Select Usuario -->
                    <div class="row" id="fade_lg">
                        <div class="col">
                            <h5 style="text-align: center; margin-bottom: 15px;">Usuarios Disponibles</h5>

                            <div class="form-floating">
                                <select class="form-select" [(ngModel)]="userValue" name="user" id="user"
                                    data-live-search="true">
                                    <option value="" selected>-- Seleccionar Usuario --</option>
                                    <option *ngFor="let d of datos" value="{{d.nombre}}">{{d.nombre}} - {{d.rfc}}</option>
                                </select>
                                <label for="user">*Usuario</label>
                            </div>
                            <br>
                        </div>
                    </div>

                    <div class="row"><br><br></div>

                    <!-- BOTON -->
                    <div class="row d-flex justify-content-center" (keydown)="EnterSubmit($event)">
                        <button class="boton" (click)="selectUser()">INGRESAR</button>
                    </div>
                </div>

            </div>
        </div>



    </div>



    <br>

    <!-- EXTRA -->
    <div class="row d-flex justify-content-center">
        <div class="col-sm-4 ">
            <p style="text-align: center;">Si no tienes una cuenta o no puedes acceder <br>
                <a style="color: blue; cursor: pointer;" (click)="openModal()"><u>Ponte en contacto con nosotros</u></a>
            </p>


        </div>
    </div>

</div>

<!-- LOGEADO -->
<div *ngIf="logged" id="fade_lg">
    <div class="d-flex justify-content-center" style="margin-top: 15px;">
        <div class="main">

            <!-- HEADER -->
            <div class="header d-flex justify-content-center">
                <h4>USUARIO</h4>
            </div>

            <!-- CONTENIDO -->
            <div class="content">

                <div class="row d-flex justify-content-center">
                    <div class="img_login d-flex justify-content-center">
                        <img src="../../assets/FAV_TIMBRANET.png" alt="">
                        <h1 class="letter" style="text-align: center;">{{obj_local.nombre_local[0]}}</h1>
                    </div>
                </div>

                <div class="info_usr">
                    <h3>{{obj_local.nombre_local}}</h3>
                </div>

                <p class="timbres"><u>Timbres: {{timbres}}</u></p>


                <!-- BOTONES -->

                <!-- MIS CFDI's -->
                <div class="row d-flex justify-content-center">
                    <button class="boton" routerLink="/cfdis/misfacturas">MIS CFDI's</button>
                </div>

                <!-- br -->
                <div class="row"><br></div>

                <!-- MIS CFDI's -->
                <div class="row d-flex justify-content-center">
                    <button class="boton" routerLink="/datos/userdata">Mis Datos</button>
                </div>


                <!-- br -->
                <div class="row"><br></div>

                <!-- Salir -->
                <div class="row d-flex justify-content-center" (keydown)="EnterSubmit($event)">
                    <button class="boton" (click)="logout()">Salir</button>
                </div>

            </div>
        </div>
    </div>
</div>

<!-- <div class="cont" *ngIf="botones==1 || botones==2" id="fade_lg"></div> -->

<!-- MODAL CANCELACIÓN -->
<div class="row">

    <modal #myModal>

        <modal-content>
            <div style="text-align: center; overflow-y: scroll; height: 500px; overflow-x: hidden;">

                <p><b><u>Contacto Timbranet</u></b></p>

                <div class="row">
                    <p><i class="fa fa-phone" aria-hidden="true"></i>&nbsp; <b>Ventas: </b>449 146 07 53</p>
                    <p><i class="fa fa-phone" aria-hidden="true"></i>&nbsp; <b>Soporte: </b>449 146 07 52</p>
                    <p><i class="fa fa-phone" aria-hidden="true"></i>&nbsp; <b>Soporte: </b>449 996 67 63</p>
                    <p><i class="fa fa-envelope-o" aria-hidden="true"></i> &nbsp; contacto&#64;facturanet.net</p>
                    <br>
                    <a href="https://www.facebook.com/Fnetmx-102737614594422" target="_blank"
                        style="text-decoration: none;"> <i class="fa fa-facebook-official" aria-hidden="true"></i>
                        &nbsp; Fnet and Systems</a>
                </div>

                <hr>

                <div class="row d-flex justify-content-center">
                    <p><b><u>Horario de Atención</u></b></p>
                    <p><b>Lunes a viernes</b></p>
                    <p>8:00 a.m. a 2:00 p.m.</p>
                    <p>3:00 p.m. a 6:00 p.m.</p>

                    <p><b>Sábados</b></p>
                    <p> 9:00 a.m. a 1:30 p.m.</p>

                </div>

                <hr>

                <div class="row d-flex justify-content-center">
                    <p><b><u>Estamos ubicados</u></b></p>
                    <p>Sierra de las Palomas #204</p>
                    <p>Bosques del Prado Sur</p>
                    <p>C.P. 20130, Aguascalientes, Ags.</p>

                    <a href="https://goo.gl/maps/3AksudnbyM4exqEH8" target="_blank"
                        style="text-decoration: none; color: rgb(179, 0, 0);">
                        <i class="fa fa-map-marker" aria-hidden="true"></i> &nbsp; Ver ubicación</a>



                </div>

            </div>

        </modal-content>

        <modal-footer>
            <button class="btn btn-dark" style="background-color: #00277a;" (click)="closeModal()">
                Cerrar
            </button>
        </modal-footer>

    </modal>
</div>`