import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CatalogosService } from '../catalogos.service';
import Swal from 'sweetalert2';
import { Router, RouterLink } from '@angular/router';
import { ModalManager } from 'ngb-modal';
import { Md5 } from 'ts-md5/dist/md5';
import { MenuComponent } from '../menu/menu.component';
import { AppComponent } from '../app.component';

import { DataService } from './data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [MenuComponent, AppComponent],
})


export class LoginComponent implements OnInit {
  encPassword = 'jorgeelchapodeoro';

  festivo: any = 1;

  obj_usr_encrypt = {
    usuario: '',
    pass: '',
  }
  // variables
  logged = false;
  fstStep = true;
  timbres = sessionStorage.getItem("timbres_restantes");
  userValue: any = '';


  obj_local = {
    nombre_local: sessionStorage.getItem("key"),
  }

  obj_usr = {
    usuario: '',
    pass: '',
  }

  constructor(private service: CatalogosService, private _router: Router,
    private menu: MenuComponent, private data: DataService,
    private modalService: ModalManager) { }


  message: string;
  block_addendas: boolean;
  flagMenu: boolean;

  ngOnInit(): void {
    this.diaEspecial();
    // suscripción a observables (candados)
    this.data.currentMenu.subscribe(flag_menu => this.flagMenu = flag_menu);
    this.data.currentMessage.subscribe(message => this.message = message);
    this.data.currentFlag.subscribe(arrayflag => this.block_addendas = arrayflag);

    // resolución de pantalla
    this.resolucion();

    // Bandera de Logeo
    if (sessionStorage.getItem("key") != null) {
      this.logged = true;
    }

  }

  // variables de login
  datos: any;
  datos2: any;
  datos3: any;
  datos4: any


  EnterSubmit(e) {
    if (e.key == 'Enter') {
      this.login()
    }
  }


  diaEspecial() {
    const fecha = new Date();
    const hoy = fecha.getDate();
    const mesActual = fecha.getMonth() + 1;

    if (mesActual == 12 && (hoy == 24 || hoy == 25)) {
      console.log("Feliz Navidad");
      this.festivo = 3;

    } else if (mesActual == 8 && hoy == 16) {
      this.festivo = 2;
      console.log("Viva México");

    } else if (mesActual == 11 && hoy == 1) {
      this.festivo = 4;
      console.log("Día de Muertos");

    } else {
      this.festivo = 1;
      console.log("Normal");

    }

    console.log(this.festivo);

    sessionStorage.setItem("festivo", this.festivo);

  }

  // ------------------------ LOGIN ------------------------
  login() {

    var validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.obj_usr.usuario.match(validRegex)) {

      // Encriptación de la contraseña
      const md5 = new Md5();

      var obj = {
        pass: md5.appendStr(this.obj_usr.pass).end(),
        usuario: this.obj_usr.usuario
      }

      this.service.get_usuario(obj).subscribe(res => {

        this.datos = res;
        console.log(this.datos)

        // Si falla
        if (this.datos.data == 0 || this.datos === 'No se encontro nada') {
          this.fallo();
          if (this.datos.data[0][0][""] == 'bloqueado') {
            this.bloqueado()
          }

        } else if (this.datos.length != 0) {
          if (this.datos.length > 1) {
            this.fstStep = false;
          } else {
            this.datos2 = this.datos[0];
            this.getSessionData();
          }
        }

      });
    } else {
      Swal.fire({
        title: '¡Error!',
        text: 'El correo es incorrecto',
        icon: 'error',
        confirmButtonText: 'Entendido'
      })
    }


  }

  selectUser() {

    // let resultado = this.datos.find(data => data.rfc == this.userValue)
    let resultado = this.datos.find(data => data.nombre == this.userValue);

    this.datos2 = resultado;
    console.log(this.datos2);

    this.getSessionData();


  }

  getSessionData() {
    // Se guardan los datos en el Local Storage
    sessionStorage.setItem('no_cliente', this.datos2.id[0])
    sessionStorage.setItem("key", this.datos2.nombre);
    sessionStorage.setItem("baseRelacionada", this.datos2.baseRelacionada);
    localStorage.setItem("baseRelacionada", this.datos2.baseRelacionada);
    sessionStorage.setItem("certificado", this.datos2.certificado);
    sessionStorage.setItem("correo", this.datos2.correo);
    sessionStorage.setItem("licencia", this.datos2.licencia);
    sessionStorage.setItem('id_llave', this.datos2.id_llave)
    sessionStorage.setItem("llave", this.datos2.llave);
    sessionStorage.setItem("Fin_certificado", this.datos2.fin_vigencia_certificado)
    sessionStorage.setItem("Inicio_certificado", this.datos2.inicio_vigencia_certificado)

    sessionStorage.setItem("noCertificado", this.datos2.noCertificado);
    sessionStorage.setItem("nombre_cer", this.datos2.nombre_cer);
    sessionStorage.setItem("nombre_key", this.datos2.nombre_key);
    // sessionStorage.setItem("logo", this.datos2.logo);
    sessionStorage.setItem("logo", this.formatLogo());
    sessionStorage.setItem("cliente_key", this.datos2.cliente_key)
    sessionStorage.setItem("passkey", this.datos2.passkey)
    sessionStorage.setItem('llavecancelacion', this.datos2.llavecancelacion)
    sessionStorage.setItem("rfc", this.datos2.rfc);
    sessionStorage.setItem("prueba", this.datos2.prueba);
    // Cuenta de banco
    sessionStorage.setItem("clabe", this.datos2.clabe)
    sessionStorage.setItem("cuenta", this.datos2.cuenta)
    sessionStorage.setItem("nombrebanco", this.datos2.nombrebanco)
    // Notaría
    sessionStorage.setItem("curpNotario", this.datos2.curpNotario)
    sessionStorage.setItem("noNotaria", this.datos2.noNotaria)
    sessionStorage.setItem("adscripcion", this.datos2.adscripcion)


    // Llamar a la funcion
    this.service.add_variable();

    //Se llama a la función ingresar
    this.ingreso();

    new Date().getFullYear() + '-' + new Date().getMonth() + '-' + new Date().getDate()

    var obj = {
      baseRel: sessionStorage.getItem("baseRelacionada")
    }

    this.service.get_empresa(obj).subscribe(res => {
      this.datos3 = res[0][0]
      console.log(this.datos3)
      var fecha = new Date().toLocaleString()
      sessionStorage.setItem("id_empresa", this.datos3.id_empresa)
      sessionStorage.setItem("clave", this.datos3.clave)
      sessionStorage.setItem("numpersct", this.datos3.numpermisosct)
      sessionStorage.setItem("calle", this.datos3.calle);
      sessionStorage.setItem("colonia", this.datos3.colonia);
      sessionStorage.setItem("cp", this.datos3.cp);
      sessionStorage.setItem("empresa", this.datos3.empresa);
      sessionStorage.setItem("estado", this.datos3.estado);
      sessionStorage.setItem("municipio", this.datos3.municipio);
      this.get_descMunicipio(this.datos3.cp)
      sessionStorage.setItem("numero_exterior", this.datos3.numero_exterior);
      sessionStorage.setItem("pais", this.datos3.pais);
      sessionStorage.setItem("regimen", this.datos3.regimen);
      sessionStorage.setItem("rfc", this.datos3.rfc);
      sessionStorage.setItem("fecha_ingreso", fecha)
      sessionStorage.setItem("aseguradora", this.datos3.nombreaseg)
      sessionStorage.setItem("numpoliza", this.datos3.numpolizaseguro)
    })


    // Obtener Timbres
    this.service.timbres_restantes().subscribe(res => {
      var timbres_restantes;
      if (res[0].timbres_restantes == 'null' || res[0].timbres_restantes == null) {
        timbres_restantes = 0
      } else {
        timbres_restantes = res[0].timbres_restantes
      }

      sessionStorage.setItem("timbres_restantes", timbres_restantes);
    })
  }

  ancho = 0;
  botones = 0;

  formatLogo() {
    var LogoURL2 = this.datos2.logo.substring(13, this.datos2.logo.length)
    // var newLogoURL = "https://api.timbranet.mx:2024/assets/" + LogoURL2
    var newLogoURL = "../../assets/" + LogoURL2
    // var newLogoURL = "https://api.timbranet.mx:2024/assets/" + LogoURL2

    return newLogoURL;
  }

  obj_municipio: any;

  get_descMunicipio(cp) {
    this.service.get_data_by_cp(cp).subscribe(res => {
      this.obj_municipio = res;
      sessionStorage.setItem('descMunicipio', this.obj_municipio[0].nombre);
    })
  }

  // Funcion para el responsive
  resolucion() {
    this.ancho = window.innerWidth;

    if (this.ancho > 1400) {
      this.botones = 1;
    } else if (this.ancho <= 1400 && this.ancho > 600) {
      this.botones = 2;
    } else if (this.ancho <= 600) {
      this.botones = 3;
    }
  }

  // NO ENCONTRO USUARIO O CONTRASEÑA
  fallo() {
    Swal.fire({
      title: 'Error!',
      text: 'No se ha encontrado usuario o la contraseña es Incorrecta',
      icon: 'error',
      confirmButtonText: 'Entendido'
    })

  }

  bloqueado() {
    Swal.fire({
      title: 'Error!',
      text: 'El usuario a Sido Bloqueado',
      icon: 'error',
      confirmButtonText: 'Entendido'
    })

  }

  obj_usuario = {
    id_usuario: ''
  }
  array_addendas: any = []

  check_addendas() {
    this.obj_usuario.id_usuario = sessionStorage.getItem('no_cliente')
    this.service.addendas_usuario(this.obj_usuario).subscribe(res => {
      this.array_addendas = res;
      console.log(this.array_addendas);
      if (this.array_addendas.length == 0) {
        this.data.changeFlag(true)
      } else {
        this.data.changeFlag(false)
      }
    })
  }



  check_menu() {
    console.log(this.datos2);

    if (this.datos2.id[0] == '' || this.datos2.id[0] == undefined) {
      this.data.changeMenu(false)
    } else {
      this.data.changeMenu(true)
    }
  }

  check_pruebas() {
    if (this.datos2.prueba == '' || this.datos2.prueba == undefined || this.datos2.prueba == 'N' || this.datos2.prueba == 'null') {
      this.data.changePrueba(false)
    } else if (this.datos2.prueba == 'S') {
      this.data.changePrueba(true)
    }
  }

  ingreso() {

    this.check_menu();
    this.check_pruebas();
    this.check_addendas();
    this.data.changeMessage(this.datos2.licencia),

      Swal.fire({
        title: 'Ingresando!',
        text: 'Tus datos son correctos',
        icon: 'success',
        confirmButtonText: 'Okay'
      }).then((result) => {
        if (result.isConfirmed) {
          this._router.navigateByUrl('/home').then(() =>
            // window.location.reload(),
            this.reloadCurrentRoute()
          )
        }
      })
  }

  reloadCurrentRoute() {

    const currentUrl = this._router.url;

    this._router.navigateByUrl('/',).then(() => {

      // ----------------------- CONVERSIÓN DE PAÍS Y ESTADO --------------------------------

      var obj_pais = {
        clavepais: '',
      }

      var obj_estado = {
        claveestado: ''
      }

      var res_pais, res_estado;

      var pais, estado;
      var pais_splited;

      obj_pais.clavepais = sessionStorage.getItem('pais');
      obj_estado.claveestado = sessionStorage.getItem('estado');

      this.service.paisid(obj_pais).subscribe(res => {
        res_pais = res;
        pais = res_pais.data[0][0].pais.split(` `)
        pais_splited = pais[0];
        sessionStorage.setItem("PaisNombre", pais_splited)


      })

      this.service.estadoid(obj_estado).subscribe(res => {
        res_estado = res;
        console.log(res_estado);

        estado = res_estado.data[0][0].descripcion
        sessionStorage.setItem("EstadoNombre", estado)

      })

      this._router.navigate(['menu']);
      this._router.navigate([currentUrl]);
    });
  }

  logout() {
    sessionStorage.removeItem("key");
    sessionStorage.removeItem("baseRelacionada");
    sessionStorage.removeItem("certificado");
    sessionStorage.removeItem("correo");
    sessionStorage.removeItem("licencia");
    sessionStorage.removeItem("llave");
    sessionStorage.removeItem("noCertificado");
    sessionStorage.removeItem("timbres_totales");
    sessionStorage.removeItem("timbres_restantes");
    sessionStorage.removeItem("timbres_usados");
    sessionStorage.removeItem("calle");
    sessionStorage.removeItem("colonia");
    sessionStorage.removeItem("cp");
    sessionStorage.removeItem("empresa");
    sessionStorage.removeItem("estado");
    sessionStorage.removeItem("municipio");
    sessionStorage.removeItem("numero_exterior");
    sessionStorage.removeItem("pais");
    sessionStorage.removeItem("regimen");
    sessionStorage.removeItem("rfc");
    sessionStorage.removeItem("logo");
    sessionStorage.removeItem("Inicio_certificado");
    sessionStorage.removeItem("Fin_certificado");
    sessionStorage.removeItem("clabe")
    sessionStorage.removeItem("cuenta")
    sessionStorage.removeItem("nombre_key")
    sessionStorage.removeItem("nombrebanco")
    sessionStorage.removeItem("cliente_key")
    sessionStorage.removeItem("nombre_cer")
    sessionStorage.removeItem("id_empresa")
    sessionStorage.removeItem("fecha_ingreso")

    sessionStorage.removeItem("EstadoNombre")
    sessionStorage.removeItem("PaisNombre")
    sessionStorage.removeItem("aseguradora")
    sessionStorage.removeItem("numpoliza")
    sessionStorage.removeItem("passkey")
    sessionStorage.removeItem("id_llave")
    sessionStorage.removeItem("llavecancelacion")
    sessionStorage.removeItem('no_cliente')


    sessionStorage.removeItem('prueba')
    sessionStorage.removeItem('numpersct')
    sessionStorage.removeItem('clave')
    sessionStorage.removeItem('festivo')
    sessionStorage.removeItem('noNotaria')
    sessionStorage.removeItem('adscripcion')
    sessionStorage.removeItem('curpNotario')


    this.logged = false;
    this.data.changeMenu(false)
    this.data.changePrueba(false)
    this.logout_msg();

    // setTimeout(function () {
    //   location.reload();
    // }, 1000);
  }

  logout_msg() {
    Swal.fire({
      title: 'Sesión Actualizado!',
      text: 'Tus sesión se ha cerrado correctamente!',
      icon: 'success',
      confirmButtonText: 'Entendido'
    })

  }

  // MODALES
  private modalRef: any;
  @ViewChild('myModal') myModal: any;

  openModal() {
    this.modalRef = this.modalService.open(this.myModal, {
      size: "md",
      modalClass: 'mymodal',
      hideCloseButton: true,
      centered: true,
      backdrop: true,
      animation: true,
      keyboard: false,
      closeOnOutsideClick: true,
      backdropClass: "modal-backdrop"
    })
  }

  closeModal() {
    this.modalService.close(this.modalRef);
  }


}



