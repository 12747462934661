import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CatalogosService {
  //url = 'https://localhost:2024/';
  url='https://api.timbranet.mx:2024/';
  

  constructor(private http: HttpClient) { }

  base = {
    baseRel: sessionStorage.getItem("baseRelacionada"),
    nombre:  sessionStorage.getItem("key")
  }
  prueba = {
    'api-key':'$2y$10$8IIyWww8.9D823nKzn4TmOmPB/VGJkeMhZ164VW.3DOHWqdcr1Fc2',
    client_key:  '766D9D9A9DA9F06B'
  }
  timbrado_descuento(){
    var obj={
      no_cliente:sessionStorage.getItem('no_cliente')
    };
    return this.http.post(`${this.url}timbres/timbrado`, obj)
  }
  timbres_restantes(){
    var obj={
      no_cliente:sessionStorage.getItem('no_cliente')
    };
    return this.http.post(`${this.url}timbres/timbres_restantes`, obj)
  }
  timbres_totales(){
    var obj={
      no_cliente:sessionStorage.getItem('no_cliente')
    };
    return this.http.post(`${this.url}timbres/timbres_totales`, obj)
  }
  timbres_usados(){
    var obj={
      no_cliente:sessionStorage.getItem('no_cliente')
    };
    return this.http.post(`${this.url}tim/timbres_usados`, obj)
  }
  timbres_desperdiciados(){
    var obj={
      no_cliente:sessionStorage.getItem('no_cliente')
    };
    return this.http.post(`${this.url}tim/desperdiciados`, obj)
  }
  
  prodigia(obj){
    return this.http.post(`${this.url}tim/prodigia`, obj)
  }
  insertar_alumno(obj){
    return this.http.post(`${this.url}alumnos/insert`,obj)
  }

  delete_alumnos(obj){
    return this.http.post(`${this.url}alumnos/delete`,obj)
  }

  actualizar_alumnos(obj){
    return this.http.put(`${this.url}alumnos`, obj)
  }
 
  recuperar_factura(obj){
    return this.http.post(`${this.url}tim/recuperar_timbre`, obj)
  }
  buscalinea(obj){
    return this.http.post(`${this.url}inegi/buscalinea`, obj)
  }

  cancelar_factura(obj){
    return this.http.post(`${this.url}tim/cancelado`, obj)
  }

  cambiar_estatus_factura(obj){
    return this.http.post(`${this.url}tim/CambiarEstatus`, obj)
  }
  checar_estatus(obj){
    return this.http.post(`${this.url}tim/status`, obj)
  }
  obtener_acuse(obj){
    return this.http.post(`${this.url}tim/acuse`, obj)
  }
  mandar_documentos(obj){
    return this.http.post(`${this.url}tim/pdf`, obj)
  }

  check_existe(obj){
    return this.http.post(`${this.url}folios/check`, obj)
  }

  addendas_usuario(obj){
    return this.http.post(`${this.url}addendas/addenda_por_usuario`, obj)
  }

  add_variable(){
    this.base.baseRel = sessionStorage.getItem("baseRelacionada");
    this.base.nombre= sessionStorage.getItem("key")
  }


  guardar_facturas(obj){
    return this.http.post(`${this.url}guardar_facturas`, obj)
  }
get_empresa(obj){
  return this.http.post(`${this.url}empresa`, obj)
}
  get_facturas(){
    return this.http.post(`${this.url}facturas`, this.base) 
  }
  get_facturas_de(obj){
    return this.http.post(`${this.url}facturas/mas`, obj) 
  }
  get_timbrado(){
    return this.http.post(`${this.url}facturas/folios`, this.base)
    
  }
  guardar_factura(obj){
    return this.http.post(`${this.url}facturas/guardar`, obj)
  }
  guardar_factura_error(obj){
    return this.http.post(`${this.url}manager/xml_error`, obj)
  }
  get_tipopermiso(){
    return this.http.post(`${this.url}tipo_permiso`,this.base)
  }
  get_api() {
    return this.http.post(`http://facturanet.net:8085/api/v1/clientes`, this.prueba);
  }
  post_actu_folio(obj) {
    return this.http.post(`http://facturanet.net:8085/api/v1/timbrado`, obj);
  }

  get_paises() {
    return this.http.post(`${this.url}pais`, this.base);
  }
  get_documento (){
    return this.http.post(`${this.url}documentos/`, this.base)
  }
  get_documento_factura() {
    return this.http.post(`${this.url}documentos/factura`, this.base)
  }
  get_documento_IEDU() {
    return this.http.post(`${this.url}documentos/IEDU`, this.base)
  }
  get_documento_leyendas() {
    return this.http.post(`${this.url}documentos/leyendas`, this.base)
  }
  
  get_documento_notacargo() {
    return this.http.post(`${this.url}documentos/notaCargo`, this.base)
  }
  get_documento_notacredito() {
    return this.http.post(`${this.url}documentos/notaCredito`, this.base)
  }
  get_documento_facturaT() {
    return this.http.post(`${this.url}documentos/facturaTransporte`, this.base)
  }
  get_documento_facturaTraslado() {
    return this.http.post(`${this.url}documentos/facturaTraslado`, this.base)
  }
  get_documento_facturaREP() {
    return this.http.post(`${this.url}documentos/RecivoPago`, this.base)
  }
  get_folio(data) {
    return this.http.post(`${this.url}documentos/folio`, data)
  }
  get_actu_folio(data) {
    return this.http.post(`${this.url}documentos/actu_folio`, data)
  }
  get_casetas() {
    return this.http.post(`${this.url}casetas`, this.base);
  }
  get_zonas() {
    return this.http.post(`${this.url}zonas`, this.base);
  }
  get_subtiporemolque() {
    return this.http.post(`${this.url}subtiporemolque`, this.base);
  }
  get_rutas() {
    return this.http.post(`${this.url}rutas`, this.base);
  }

  get_clientes() {
    return this.http.post(`${this.url}clientes`, this.base);
  }
  get_estado() {
    return this.http.post(`${this.url}estado`, this.base);;
  }
  get_estados(pais) {
    return this.http.post(`${this.url}estado/${pais}`, this.base);
  }
  get_municipios(estado) {
    return this.http.post(`${this.url}municipio/${estado}`, this.base);
  }
  get_localidades(estado) {
    return this.http.post(`${this.url}localidades/${estado}`, this.base);
  }
  get_colonias(municipio) {
    return this.http.post(`${this.url}colonia/${municipio}`, this.base);
  }
  get_cp(municipio) {
    return this.http.post(`${this.url}cp/${municipio}`, this.base)
  }
  get_formasdepago() {
    return this.http.post(`${this.url}formasdepago`, this.base)
  }
  get_ivas() {
    return this.http.post(`${this.url}ivas`, this.base)
  }
  get_retenciones() {
    return this.http.post(`${this.url}retencion`, this.base)
  }
  get_moneda() {
    return this.http.post(`${this.url}moneda`, this.base)
  }
  get_metodosdepago() {
    return this.http.post(`${this.url}metodopago`, this.base)
  }
  get_usocfdi() {
    return this.http.post(`${this.url}usocfdi`, this.base);
  }
  get_usocfdibyid(data) {
    return this.http.post(`${this.url}usocfdi/${data}`, this.base);
  }
  get_usocfdibyuso(data) {
    return this.http.post(`${this.url}usocfdi/uso/${data}`, this.base);
  }
  get_materialpeligroso(data) {
    return this.http.post(`${this.url}materialespeligrosos/${data}`, this.base)
  }
  get_materialpeligrosoporid(data) {
    return this.http.post(`${this.url}materialespeligrosos/porid/${data}`, this.base)
  }
  get_data_by_cp(data) {
    return this.http.post(`${this.url}cp/porcp/${data}`, this.base)
  }
  get_localidad_by_cp(data) {
    return this.http.post(`${this.url}localidades/porcp/${data}`, this.base)
  }
  get_combustibles() {
    return this.http.post(`${this.url}combustibles`, this.base)
  }
  get_conceptos() {
    return this.http.post(`${this.url}conceptoscobro`, this.base)
  }
  get_servicios() {
    return this.http.post(`${this.url}conceptoscobro/servicios`, this.base)
  }
  get_cadena(XML) {
    return this.http.post(`http://timbranet.mx/cadena_original/`, XML) // http://timbranet.mx/cadena_original/

  }
  get_sucursal() {
    return this.http.post(`${this.url}sucursales`, this.base);
  }
  get_departamentos() {
    return this.http.post(`${this.url}departamentos`, this.base);
  }
  get_puestos() {
    return this.http.post(`${this.url}puestos`, this.base);
  }
  get_empleados() {
    return this.http.post(`${this.url}empleados`, this.base);
  }
  get_embalaje(data) {
    return this.http.post(`${this.url}embalaje/${data}`, this.base)
  }
  get_embalajebyid(data) {
    return this.http.post(`${this.url}embalaje/porid/${data}`, this.base)
  }


  get_udm(data) {
    return this.http.post(`${this.url}medidas/${data}`, this.base)
  }
  get_uombyid(data) {
    return this.http.post(`${this.url}medidas/porid/${data}`, this.base)
  }
  get_fraccion(data) {
    return this.http.post(`${this.url}fracciones/${data}`, this.base)
  }
  get_productossat(data) {
    return this.http.post(`${this.url}productossat/${data}`, this.base)
  }
  get_productossatbyid(data) {
    return this.http.post(`${this.url}productossat/porid/${data}`, this.base)
  }
  get_productos() {
    return this.http.post(`${this.url}productos`, this.base)
  }
  get_tipoviaje() {
    return this.http.post(`${this.url}tipoviaje`, this.base)
  }
  get_configuracionauto() {
    return this.http.post(`${this.url}configuracionautotransporte`, this.base)
  }
  get_productosadvance() {
    return this.http.post(`${this.url}productosmas`, this.base)
  }
  get_familia() {
    return this.http.post(`${this.url}familias`, this.base);
  }
  get_subfamilias() {
    return this.http.post(`${this.url}subfamilias`, this.base);
  }
  get_proveedores() {
    return this.http.post(`${this.url}proveedores`, this.base);
  }
  get_ieps() {
    return this.http.post(`${this.url}ieps`, this.base);
  }
  get_uom(data) {
    return this.http.post(`${this.url}medidas/${data}`, this.base)
  }
  get_uom_todo() {
    return this.http.post(`${this.url}medidas/`, this.base)
  }
  get_articulos() {
    return this.http.post(`${this.url}articulos`, this.base)
  }
  get_articulos_sat(data) {
    return this.http.post(`${this.url}articulossat/${data}`, this.base)
  }
  get_articulos_sat_by_id(data) {
    return this.http.post(`${this.url}articulossat/porid/${data}`, this.base)
  }
  //get_almacen() {
    //return this.http.post(`${this.url}almacen`, this.base);
  //}
  get_categorias() {
    return this.http.post(`${this.url}categorias`, this.base);
  }
  get_divisiones() {
    return this.http.post(`${this.url}divisionesoperativas`, this.base)
  }
  get_subdivisiones() {
    return this.http.post(`${this.url}subdivisiones`, this.base)
  }
  get_lineas() {
    return this.http.post(`${this.url}lineas`, this.base)
  }
  get_marcas() {
    return this.http.post(`${this.url}marcas`, this.base)
  }
  get_modelos() {
    return this.http.post(`${this.url}modelos`, this.base)
  }
  get_tipounidades() {
    return this.http.post(`${this.url}tiposunidades`, this.base);
  }
  get_motores() {
    return this.http.post(`${this.url}motores`, this.base);
  }
  get_operadores() {
    return this.http.post(`${this.url}operadores`, this.base)
  }
  get_vehiculos() {
    return this.http.post(`${this.url}vehiculos`, this.base)
  }
  get_regimen_fisica() {
    return this.http.post(`${this.url}regimenfiscal/fisica`, this.base)
  }
  get_regimen(){
    return this.http.post(`${this.url}regimenfiscal`, this.base)
  }
  get_regimen_moral() {
    return this.http.post(`${this.url}regimenfiscal/moral`, this.base)
  }
  get_plaza() {
    return this.http.post(`${this.url}plazas`, this.base)
  }
  get_remolques() {
    return this.http.post(`${this.url}unidades/remolques`, this.base)
  }
  // get_dolly(){
  //   return this.http.post(`${this.url}unidades/dolly`, this.base)
  // }
  get_tractores() {
    return this.http.post(`${this.url}unidades/tractores`, this.base)
  }
  get_sello(data) {
    return this.http.post(`${this.url}sello`, data)
  }
  timbrado(xml) {
    return this.http.post(`${this.url}tim`, xml)
  }


  // LOGIN
  get_usuario(obj_usr: any) {
    return this.http.post(`${this.url}logclientes`, obj_usr)
  }

  // Registrar empresas
  insertar_empresa(obj_usr: any) {
    return this.http.post(`${this.url}empresa`, obj_usr)
  }
  //-------------------------------datospersonales
  guardar_cer(obj_usr: any) {
   
    return this.http.post(`${this.url}datospersonales/cer`, obj_usr)
  }
  guardar_key(obj_usr: any) {
    return this.http.post(`${this.url}datospersonales/key`, obj_usr)
  }
  guardar_logo(obj_usr: any) {
    return this.http.post(`${this.url}datospersonales/logo`, obj_usr)
  }
  quitar_logo(obj_usr: any) {
    return this.http.post(`${this.url}datospersonales/quitarlogo`, obj_usr)
  }
  guardar_nombre_banco(obj_usr: any) {
    return this.http.post(`${this.url}datospersonales/nombre_banco`, obj_usr)
  }
  guardar_contra(obj_usr: any) {
    return this.http.post(`${this.url}datospersonales/contracuenta`, obj_usr)
  }
  guardar_clabe(obj_usr: any) {
    return this.http.post(`${this.url}datospersonales/clabe`, obj_usr)
  }
  guardar_cuenta(obj_usr: any) {
    return this.http.post(`${this.url}datospersonales/ctabanco`, obj_usr)
  }
  estadoid(obj_usr: any) {
    return this.http.post(`${this.url}estado/estadoid`, obj_usr)
  }
  paisid(obj_usr: any) {
    return this.http.post(`${this.url}estado/paisid`, obj_usr)
  }
  guardar_empresa(obj_usr: any) {
    return this.http.post(`${this.url}empresa/guardar`, obj_usr)
  }
  //---------------------------------------ivas
  insertar_retenciones(obj_usr: any) {
    return this.http.post(`${this.url}ivas/insert_retencion`, obj_usr)
  }
  insertar_ieps(obj_usr: any) {
    return this.http.post(`${this.url}ivas/insert_ieps`, obj_usr)
  }
   insertar_isr(obj_usr: any) {
    return this.http.post(`${this.url}ivas/insert_isr`, obj_usr)
  }
  actualizar_retenciones(obj_usr: any) {
    return this.http.post(`${this.url}ivas/update_retencion`, obj_usr)
  }
  actualizar_ieps(obj_usr: any) {
    return this.http.post(`${this.url}ivas/update_ieps`, obj_usr)
  }
  actualizar_isr(obj_usr: any) {
    return this.http.post(`${this.url}ivas/update_isr`, obj_usr)
  }
  select_isr(obj_usr: any) {
    return this.http.post(`${this.url}ivas/select_isr`, obj_usr)
  }
  //-----------------------------------------------------
  charly(key:File){
    console.log(key)
    return this.http.post(`http://facturanet.ddns.net:8080/cadena_original/certificado.php`,key)
  }
  charly2(obj:any){
    console.log(obj)
    return this.http.post(`http://facturanet.ddns.net:8080/cadena_original/certificado.php`, obj)
  }
  certificado(obj:any){
    return this.http.post(`${this.url}logclientes/certificado`, obj)
  }
  //avisos 
  select_avisos(body:any){
    return this.http.post(`${this.url}manager/anuncio_select`,body)
  }
  seleccionar_alumnos(obj){
    return this.http.post(`${this.url}alumnos`, obj)
  }


  // SECCIÓN AVISOS 
  get_avisos() {
    return this.http.get(`${this.url}manager/anuncio_manager`)
  }



  // NUEVAS PETICIONES CCP 3.0
  get_RegimenAduanero(){
    return this.http.get(`${this.url}carta3/regimenaduanero`)   
  }

  get_Cofepris(){
    return this.http.get(`${this.url}carta3/sectorcofepris`);
  }


}
