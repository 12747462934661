import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DataService {

  private messageSource = new BehaviorSubject<string>('')
  private arrayflag = new BehaviorSubject<boolean>(false)
  private flag_menu = new BehaviorSubject<boolean>(false)
  private flag_prueba = new BehaviorSubject<boolean>(false)


  currentMessage = this.messageSource.asObservable();
  currentFlag = this.arrayflag.asObservable();
  currentMenu = this.flag_menu.asObservable();
  currentPrueba = this.flag_prueba.asObservable();

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  changeFlag(arrayflag: boolean) {
    this.arrayflag.next(arrayflag)
  }

  changeMenu(flag_menu: boolean){
    this.flag_menu.next(flag_menu)
  }

  changePrueba(flagprueba: boolean){
    this.flag_prueba.next(flagprueba)
  }

  constructor() { }

}
