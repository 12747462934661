import { NgModule } from '@angular/core';
import { Routes, RouterModule} from '@angular/router';
//import { HerramientasComponent } from './herramientas/herramientas.component';
import { HomeComponent } from './home/home/home.component';
import { LoginComponent } from './login/login.component';
import { SauronGuard } from './sauron.guard';
import { CustomPreloadStrategy } from './custom-preload';

const routes: Routes = [
  { path: 'logistica', loadChildren: () => import('./catalogos/logistica/logistica.module').then(m => m.LogisticaModule), canActivate: [SauronGuard] },
  { path: 'mantenimiento', loadChildren: () => import('./catalogos/mantenimiento/mantenimiento.module').then(m => m.MantenimientoModule), canActivate: [SauronGuard] },
  { path: 'basico', loadChildren: () => import('./catalogos/basicos/basicos.module').then(m => m.BasicosModule), canActivate: [SauronGuard] },
  { path: 'nomina', loadChildren: () => import('./catalogos/nomina/nomina.module').then(m => m.NominaModule), canActivate: [SauronGuard] },
  { path: 'recursoshumanos', loadChildren: () => import('./catalogos/recursoshumanos/recursoshumanos.module').then(m => m.RecursoshumanosModule), canActivate: [SauronGuard] },
  { path: 'sat', loadChildren: () => import('./catalogos/sat/sat.module').then(m => m.SatModule), canActivate: [SauronGuard] },
  { path: 'contables', loadChildren: () => import('./catalogos/contablesyadministrativos/contablesyadministrativos.module').then(m => m.ContablesyadministrativosModule), canActivate: [SauronGuard] },
  { path: 'cfdis', loadChildren: () => import('./datospersonales/cfdis/cfdis.module').then(m => m.CfdisModule) },
  { path: 'ventas', loadChildren: () => import('./ventas/ventas.module').then(m => m.VentasModule), canActivate: [SauronGuard] },
  { path: 'complementos', loadChildren: () => import('./ventas/complementos/complementos/complementos.module').then(m => m.ComplementosModule) },
  { path: 'datos', loadChildren: () => import('./datospersonales/datos/datos.module').then(m => m.DatosModule) },
  { path: 'config', loadChildren: () => import('./datospersonales/config/config.module').then(m => m.ConfigModule) },
  { path: 'transporte', loadChildren: () => import('./ventas/transporte/transporte.module').then(m => m.TransporteModule) },
  //{ path: 'adendas', component: AdendasComponent, canActivate: [SauronGuard] },
  { path: 'home', component: HomeComponent, canActivate: [SauronGuard] },
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: '/login', pathMatch: 'full' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
      relativeLinkResolution: 'legacy',
      preloadingStrategy: CustomPreloadStrategy
    })],
    providers: [
      CustomPreloadStrategy
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
