// GENERAL
import { BrowserModule } from '@angular/platform-browser';

import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule } from '@angular/forms';
import { MenuComponent } from './menu/menu.component';
// BOOTSTRAP
import { HomeComponent } from './home/home/home.component';
import { LoginComponent } from './login/login.component';
import { ModalModule } from 'ngb-modal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CdkAccordionModule } from '@angular/cdk/accordion';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomeComponent,
    LoginComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    //NgbModule,
    // Ng2SearchPipeModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    ModalModule,
    CdkAccordionModule,
    
    //TextMaskModule,
  ],
  exports: [
    // MatStepperModule,
    // MatFormFieldModule,
    // MatInputModule,
    // ReactiveFormsModule,
    // MatButtonModule,
    // MatAutocompleteModule,
    // CdkStepperModule,

  ],
  providers: [
    // ExcelService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
