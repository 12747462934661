<nav class="navbars navbar-expand-lg navbar-red navbar-dark" style="z-index: 100;" id="fade_lg">

    <!-- ANIMACIÓN -->
    <div class="wrapper"></div>

    <!-- CONTENIDO -->
    <div class="d-flex justify-content-between">

        <!-- INICIO -->
        <div class="p-1">

            <!-- LOGO MOVIL -->
            <img routerLink="./home" *ngIf="botones==3" src="../../assets/{{url_logo}}" style="width: 60vw;
                     margin: 10px;" alt="TransportNetv0.1">

            <!-- Logo NORMAL -->
            <img routerLink="./home" *ngIf="botones==1 || botones==2" src="../../assets/{{url_logo}}" class="logo"
                alt="TransportNetv0.1">


            <!-- ESCRITORIO -->
            <div class="row" *ngIf="botones==1 || botones==2" style="margin-left: 17.5rem;">

                <!-- CATÁLOGOS -->
                <ul class="col navbar-nav">
                    <div class="nav-item dropdown">

                        <a class="col nav-link dropdown-toggle a_nav" id="dLabel" role="button" data-toggle="dropdown">
                            CATÁLOGOS <span class="caret"></span>
                        </a>

                        <ul (click)="closeOffcanvas($event)" class="dropdown-menu multi-level" role="menu"
                            aria-labelledby="dropdownMenu">

                            <!-- CATÁLOGOS BÁSICOS -->
                            <li class="">



                                <a class="dropdown-item" routerLink="/basico/clientes">Clientes</a>
                                <a class="dropdown-item" routerLink="/basico/alumnos">Alumnos</a>
                                <a class="dropdown-item" routerLink="/basico/articulos">Artículos y Servicios</a>

                                <!-- <li> <a class="dropdown-item" routerLink="/basico/proveedores">Proveedores</a></li>
                                    <li><a class="dropdown-item" routerLink="/basico/familiasysubfamilias">Serie
                                            Documentos</a></li> -->


                            </li>

                            <li class="divider"></li>
                        </ul>
                    </div>
                </ul>

                <!-- TRANSPORTE -->
                <ul class="col navbar-nav">
                    <div class="nav-item dropdown">

                        <a class="col nav-link dropdown-toggle a_nav"
                            [ngClass]="{'disabled_menu': licencia == 'I' || licencia == 'B' }" id="dLabel" role="button"
                            data-toggle="dropdown">
                            TRANSPORTE <span class="caret"> </span><i *ngIf="licencia == 'I' || licencia == 'B' "
                                class="fa fa-lock" aria-hidden="true"></i>
                        </a>

                        <ul (click)="closeOffcanvas($event)" class="dropdown-menu multi-level" role="menu"
                            aria-labelledby="dropdownMenu">

                            <!-- RECURSOS HUMANOS -->

                            <!-- 1er Nivel -->
                            <li class="dropdown-submenu">
                                <a class="dropdown-item" tabindex="-1" (click)="do1()">Recursos Humanos</a>

                                <!-- 2do Nivel -->
                                <ul class="dropdown-menu">

                                    <li>
                                        <a class="dropdown-item" routerLink="/recursoshumanos/empleados">Empleados y
                                            Operadores</a>
                                    </li>

                                    <!-- <li>
                                            <a class="dropdown-item" routerLink="/recursoshumanos/puestos">Adicionales</a>
                                        </li> -->

                                </ul>
                            </li>


                            <!-- LOGÍSTICA -->

                            <!-- 1er Nivel -->
                            <li class="dropdown-submenu">
                                <a class="dropdown-item" tabindex="-1" (click)="do1()">Logística</a>

                                <!-- 2do Nivel -->
                                <ul class="dropdown-menu">
                                    <!-- <li>
                                        <a class="dropdown-item" routerLink="/logistica/tipoviaje">
                                            Tipo de Viaje</a>
                                    </li> -->
                                    <li>
                                        <a class="dropdown-item" routerLink="/logistica/plazas">
                                            Estados Y Provincias</a>
                                    </li>
                                    <!-- <li>
                                            <a class="dropdown-item" routerLink="/logistica/rutas">
                                                Rutas</a>
                                        </li> -->

                                    <li>
                                        <a class="dropdown-item"
                                            routerLink="/logistica/catalogosadicionales">Adicionales</a>
                                    </li>

                                </ul>
                            </li>


                            <!-- MANTENIMIENTO -->

                            <!-- 1er Nivel -->
                            <li class="dropdown-submenu">
                                <a class="dropdown-item" tabindex="-1" (click)="do1()">Mantenimiento</a>

                                <!-- 2do Nivel -->
                                <ul class="dropdown-menu">

                                    <li>
                                        <a class="dropdown-item"
                                            routerLink="/mantenimiento/vehiculosycamiones">Vehículos y
                                            Tractores</a>
                                    </li>
                                    <li><a class="dropdown-item" routerLink="/mantenimiento/catalogosm">Adicionales</a>
                                    </li>

                                </ul>
                            </li>

                        </ul>
                    </div>
                </ul>

                <!-- VENTAS -->
                <ul class="col navbar-nav">
                    <div class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle a_nav" id="dLabel" role="button" data-toggle="dropdown" (click)="checkData()">
                            VENTAS <span class="caret"></span>
                        </a>
                        <ul (click)="closeOffcanvas($event)" class="dropdown-menu multi-level" role="menu"
                            aria-labelledby="dropdownMenu">

                            <!-- 1er Nivel -->

                            <a class="dropdown-item" tabindex="-1" (click)="do1()" routerLink="/ventas/factura">Factura
                                / Cargo / Crédito</a>

                            <!-- <a class="dropdown-item" tabindex="-1" (click)="do1()"
                                routerLink="/transporte/cfdistandard">CFDI
                                Traslado</a> -->

                            <a class="dropdown-item" tabindex="-1" (click)="do1()"
                                routerLink="/ventas/recivo_electronico">Documento de Pago</a>


                            <a class="dropdown-item" [ngClass]="{'disabled': licencia == 'I' || licencia == 'B' }"
                                tabindex="-1" (click)="do1()" routerLink="/transporte/facturatransporte">Factura de
                                Transporte &nbsp;<i *ngIf="licencia == 'I' || licencia == 'B' " class="fa fa-lock"
                                    aria-hidden="true"></i></a>

                            <a class="dropdown-item" [ngClass]="{'disabled': licencia == 'I' || licencia == 'B' }"
                                tabindex="-1" (click)="do1()" routerLink="/transporte/cfditransporte">CFDI
            
                                Traslado CCP &nbsp;<i *ngIf="licencia == 'I' || licencia == 'B' " class="fa fa-lock"
                                    aria-hidden="true"></i></a>
<!--

    Inicio de complementos
-->
                            <a class="dropdown-item" [ngClass]="{'disabled':  licencia == 'B' }"
                                tabindex="-1" (click)="do1()" routerLink="/complementos/detallistas">Detallista &nbsp;<i *ngIf="licencia == 'I' || licencia == 'B' " class="fa fa-lock"
                                    aria-hidden="true"></i></a>

                            <a class="dropdown-item" [ngClass]="{'disabled': licencia == 'B' }"
                                tabindex="-1" (click)="do1()" routerLink="/complementos/Instituciones_privadas">Instituciones Privadas &nbsp;<i *ngIf="licencia == 'I' || licencia == 'B' " class="fa fa-lock"
                                    aria-hidden="true"></i></a>
                                    
                            <!-- <a class="dropdown-item" [ngClass]="{'disabled': licencia == 'B' }"
                                tabindex="-1" (click)="do1()" routerLink="/complementos/Constructoras">Constructoras &nbsp;<i *ngIf="licencia == 'I' || licencia == 'B' " class="fa fa-lock"
                                    aria-hidden="true"></i></a> -->
                                    
                            <!-- <a class="dropdown-item" [ngClass]="{'disabled':  licencia == 'B' }"
                                tabindex="-1" (click)="do1()" routerLink="/complementos/Notarios">Notarios &nbsp;<i *ngIf="licencia == 'I' || licencia == 'B' " class="fa fa-lock"
                                    aria-hidden="true"></i></a> -->
                                        
                <!-- <a class="dropdown-item" [ngClass]="{'disabled':  licencia == 'B' }"
                                tabindex="-1" (click)="do1()" routerLink="/complementos/leyendasfiscales">leyendas Fiscales &nbsp;<i *ngIf="licencia == 'I' || licencia == 'B' " class="fa fa-lock"
                                    aria-hidden="true"></i></a> -->

                            <a class="dropdown-item" [ngClass]="{'disabled': block_addendas == true }" tabindex="-1"
                                (click)="do1()" routerLink="/config/adendas">Addendas
                                <i *ngIf="block_addendas" class="fa fa-lock" aria-hidden="true"></i>
                            </a>


                            <li class="divider"></li>

                        </ul>
                    </div>
                </ul>

                <!-- CONFIGURACIÓN -->
                <ul class="col navbar-nav">
                    <div class="nav-item dropdown">

                        <a class="col nav-link dropdown-toggle a_nav" id="dLabel" role="button" data-toggle="dropdown">
                            CONFIGURACIÓN <span class="caret"></span>
                        </a>

                        <ul (click)="closeOffcanvas($event)" class="dropdown-menu multi-level" role="menu"
                            aria-labelledby="dropdownMenu">

                            <!-- CATÁLOGOS BÁSICOS -->
                            <li class="">

                                <!-- 1er Nivel -->
                                <a class="dropdown-item" routerLink="/basico/familiasysubfamilias" tabindex="-1">Serie
                                    Documentos</a>
                                <a class="dropdown-item" [ngClass]="{'disabled': licencia == 'I' || licencia == 'B' }"
                                    routerLink="/basico/proveedores" tabindex="-1">Proveedores
                                    &nbsp;<i *ngIf="licencia == 'I' || licencia == 'B' " class="fa fa-lock"
                                        aria-hidden="true"></i> </a>


                                <a class="dropdown-item" routerLink="/config/impuestos" tabindex="-1">Impuestos y
                                    Retenciones</a>
                                <a class="dropdown-item" routerLink="/config/subusuario" tabindex="-1">Mis Usuarios</a>
                                <a class="dropdown-item" routerLink="/datos/userdata" tabindex="-1">Mis Datos</a>

                            </li>

                        </ul>
                    </div>
                </ul>

            </div>

            <!-- CELULAR -->
            <div class="collapse navbar-collapse" id="navbarNavDropdown" style="margin-left: 2vw;" *ngIf="botones==3">

                <!-- Separacion TABLET -->
                <div class="col" style="margin-left: 250px;"></div>

                <!-- CATÁLOGOS -->
                <ul class="col navbar-nav">
                    <div class="nav-item dropdown">

                        <a class="col nav-link dropdown-toggle a_nav" id="dLabel" role="button" data-toggle="dropdown">
                            CATÁLOGOS <span class="caret"></span>
                        </a>

                        <ul (click)="closeOffcanvas($event)" class="dropdown-menu multi-level" role="menu"
                            aria-labelledby="dropdownMenu">

                            <!-- CATÁLOGOS BÁSICOS -->
                            <li class="">

                                <a class="dropdown-item" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                                    aria-controls="navbarNavDropdown" aria-expanded="false"
                                    aria-label="Toggle navigation" routerLink="/basico/clientes">Clientes</a>
                                <a class="dropdown-item" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                                    aria-controls="navbarNavDropdown" aria-expanded="false"
                                    aria-label="Toggle navigation" routerLink="/basico/articulos">Artículos y
                                    Servicios</a>

                            </li>

                            <li class="divider"></li>
                        </ul>
                    </div>
                </ul>

                <!-- TRANSPORTE -->
                <ul class="col navbar-nav">
                    <div class="nav-item dropdown">

                        <a class="col nav-link dropdown-toggle a_nav"
                            [ngClass]="{'disabled_menu': licencia == 'I' || licencia == 'B' }" id="dLabel" role="button"
                            data-toggle="dropdown">
                            TRANSPORTE <span class="caret"> </span><i *ngIf="licencia == 'I' || licencia == 'B' "
                                class="fa fa-lock" aria-hidden="true"></i>
                        </a>

                        <ul (click)="closeOffcanvas($event)" class="dropdown-menu multi-level" role="menu"
                            aria-labelledby="dropdownMenu">

                            <!-- RECURSOS HUMANOS -->

                            <!-- 1er Nivel -->
                            <li class="dropdown-submenu">
                                <a class="dropdown-item" tabindex="-1" (click)="do1()">Recursos Humanos</a>

                                <!-- 2do Nivel -->
                                <ul class="dropdown-menu">

                                    <li>
                                        <a class="dropdown-item" data-bs-toggle="collapse"
                                            data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                            aria-expanded="false" aria-label="Toggle navigation"
                                            routerLink="/recursoshumanos/empleados">Empleados y
                                            Operadores</a>
                                    </li>

                                    <!-- <li>
                                            <a class="dropdown-item" routerLink="/recursoshumanos/puestos">Adicionales</a>
                                        </li> -->

                                </ul>
                            </li>


                            <!-- LOGÍSTICA -->

                            <!-- 1er Nivel -->
                            <li class="dropdown-submenu">
                                <a class="dropdown-item" tabindex="-1" (click)="do1()">Logística</a>

                                <!-- 2do Nivel -->
                                <ul class="dropdown-menu">
                                    <!-- <li>
                                        <a class="dropdown-item" data-bs-toggle="collapse"
                                            data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                            aria-expanded="false" aria-label="Toggle navigation"
                                            routerLink="/logistica/tipoviaje">
                                            Tipo de Viaje</a>
                                    </li> -->
                                    <li>
                                        <a class="dropdown-item" data-bs-toggle="collapse"
                                            data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                            aria-expanded="false" aria-label="Toggle navigation"
                                            routerLink="/logistica/plazas">
                                            Estados Y Provincias</a>
                                    </li>
                                    <!-- <li>
                                            <a class="dropdown-item" routerLink="/logistica/rutas">
                                                Rutas</a>
                                        </li> -->

                                    <li>
                                        <a class="dropdown-item" data-bs-toggle="collapse"
                                            data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                            aria-expanded="false" aria-label="Toggle navigation"
                                            routerLink="/logistica/catalogosadicionales">Adicionales</a>
                                    </li>

                                </ul>
                            </li>


                            <!-- MANTENIMIENTO -->

                            <!-- 1er Nivel -->
                            <li class="dropdown-submenu">
                                <a class="dropdown-item" tabindex="-1" (click)="do1()">Mantenimiento</a>

                                <!-- 2do Nivel -->
                                <ul class="dropdown-menu">

                                    <li>
                                        <a class="dropdown-item" data-bs-toggle="collapse"
                                            data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                            aria-expanded="false" aria-label="Toggle navigation"
                                            routerLink="/mantenimiento/vehiculosycamiones">Vehículos y
                                            Tractores</a>
                                    </li>
                                    <li><a class="dropdown-item" data-bs-toggle="collapse"
                                            data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                            aria-expanded="false" aria-label="Toggle navigation"
                                            routerLink="/mantenimiento/catalogosm">Adicionales</a>
                                    </li>

                                </ul>
                            </li>

                        </ul>
                    </div>
                </ul>

                <!-- VENTAS -->
                <ul class="col navbar-nav">
                    <div class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle a_nav" id="dLabel" role="button" data-toggle="dropdown" (click)="checkData()">
                            VENTAS <span class="caret" ></span>
                        </a>
                        <ul (click)="closeOffcanvas($event)" class="dropdown-menu multi-level" role="menu"
                            aria-labelledby="dropdownMenu">

                            <!-- 1er Nivel -->

                            <a class="dropdown-item" tabindex="-1" (click)="do1()" data-bs-toggle="collapse"
                                data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                aria-expanded="false" aria-label="Toggle navigation"
                                routerLink="/ventas/factura">Factura
                                / Cargo / Crédito</a>

                            <!-- <a class="dropdown-item" tabindex="-1" (click)="do1()" data-bs-toggle="collapse"
                                data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                aria-expanded="false" aria-label="Toggle navigation"
                                routerLink="/transporte/cfdistandard">CFDI
                                Traslado</a> -->

                            <a class="dropdown-item" tabindex="-1" (click)="do1()" data-bs-toggle="collapse"
                                data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                aria-expanded="false" aria-label="Toggle navigation"
                                routerLink="/ventas/recivo_electronico">Documento de Pago</a>


                            <a class="dropdown-item" [ngClass]="{'disabled': licencia == 'I' || licencia == 'B' }"
                                tabindex="-1" (click)="do1()" data-bs-toggle="collapse"
                                data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                aria-expanded="false" aria-label="Toggle navigation"
                                routerLink="/transporte/facturatransporte">Factura de
                                Transporte &nbsp;<i *ngIf="licencia == 'I' || licencia == 'B' " class="fa fa-lock"
                                    aria-hidden="true"></i></a>

                            <a class="dropdown-item" [ngClass]="{'disabled': licencia == 'I' || licencia == 'B' }"
                                tabindex="-1" (click)="do1()" data-bs-toggle="collapse"
                                data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                aria-expanded="false" aria-label="Toggle navigation"
                                routerLink="/transporte/cfditransporte">CFDI
                                Traslado CCP &nbsp;<i *ngIf="licencia == 'I' || licencia == 'B' " class="fa fa-lock"
                                    aria-hidden="true"></i></a>


                            <a class="dropdown-item" [ngClass]="{'disabled': block_addendas == true }" tabindex="-1"
                                (click)="do1()" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                                aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"
                                routerLink="/config/adendas">Addendas
                                <i *ngIf="block_addendas" class="fa fa-lock" aria-hidden="true"></i>
                            </a>


                            <li class="divider"></li>

                        </ul>
                    </div>
                </ul>

                <!-- CONFIGURACIÓN -->
                <ul class="col navbar-nav">
                    <div class="nav-item dropdown">

                        <a class="col nav-link dropdown-toggle a_nav" id="dLabel" role="button" data-toggle="dropdown">
                            CONFIGURACIÓN <span class="caret"></span>
                        </a>

                        <ul (click)="closeOffcanvas($event)" class="dropdown-menu multi-level" role="menu"
                            aria-labelledby="dropdownMenu">

                            <!-- CATÁLOGOS BÁSICOS -->
                            <li class="">

                                <!-- 1er Nivel -->
                                <a class="dropdown-item" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                                    aria-controls="navbarNavDropdown" aria-expanded="false"
                                    aria-label="Toggle navigation" routerLink="/basico/familiasysubfamilias"
                                    tabindex="-1">Serie
                                    Documentos</a>
                                <a class="dropdown-item" [ngClass]="{'disabled': licencia == 'I' || licencia == 'B' }"
                                    data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                                    aria-controls="navbarNavDropdown" aria-expanded="false"
                                    aria-label="Toggle navigation" routerLink="/basico/proveedores"
                                    tabindex="-1">Proveedores
                                    &nbsp;<i *ngIf="licencia == 'I' || licencia == 'B' " class="fa fa-lock"
                                        aria-hidden="true"></i> </a>
                                <a class="dropdown-item" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                                    aria-controls="navbarNavDropdown" aria-expanded="false"
                                    aria-label="Toggle navigation" routerLink="/config/impuestos"
                                    tabindex="-1">Impuestos y Retenciones</a>

                                <a class="dropdown-item" routerLink="/config/subusuario" tabindex="-1">Mis Usuarios</a>

                                <a class="dropdown-item" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                                    aria-controls="navbarNavDropdown" aria-expanded="false"
                                    aria-label="Toggle navigation" routerLink="/datos/userdata" tabindex="-1">Mis
                                    Datos</a>

                            </li>

                        </ul>
                    </div>
                </ul>

                <!-- USUARIO -->
                <div style="margin-top: 10px;" *ngIf="botones==3">
                    <a class="navlink" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
                        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"
                        routerLink="./login" title="Usuario" routerLinkActive="router-link-active-lgn">
                        <i class="fa fa-user-circle-o" aria-hidden="true" style="font-size: 30px; color: black;"></i>
                    </a>
                </div>

            </div>

        </div>

        <!-- CENTRO -->
        <div class="p-1"> </div>

        <!-- FIN -->
        <div class="p-2" style="z-index: 20;">

            <!-- USUARIO -->
            <div style="margin: 1vw;" *ngIf="botones==1 ||  botones == 2">
                <a class="navlink" routerLink="./login" title="Usuario" routerLinkActive="router-link-active-lgn">
                    <i class="fa fa-user-circle-o" aria-hidden="true" style="font-size: 30px;"></i>
                </a>
            </div>


            <!-- Botón responsive Opciones -->
            <button *ngIf="botones==3" class="navbar-toggler" style="color: #3c3f4e;" type="button"
                data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                aria-expanded="false" aria-label="Toggle navigation" style="text-align: center; margin-top: 2vw;">
                <span class="navbar-toggler-icon" style="color: #3c3f4e;"></span>
            </button>
        </div>

    </div>
</nav>