<!-- TIPO DE PRUEBA -->
<div class="modo d-flex justify-content-center" id="fade_xl" *ngIf="prueba_msg==true">{{modo}}</div>

<!-- NOMBRE USUARIO -->
<div class="nombre d-flex justify-content-center" id="fade_xl">{{rfc}} - {{nombre}}</div>


<!-- Home de Escritorio -->
<div class="cont" id="fade_lg" *ngIf="botones==1 || botones==2">

    <div class="texto center">

        <div class="row d-flex justify-content-center">

            <div class="row" style="margin-left: 2%; margin-right: 2%;">

                <div *ngIf="avisosFiltered.length == 0" class="aviso_p">
                    No existen avisos Recientes
                </div>

                <div *ngIf="avisosFiltered.length == 1">
                    <div *ngFor="let i of avisosFiltered" [ngClass]="color(i)">
                        {{i.descripcion}}
                    </div>
                </div>

                <cdk-accordion style="margin: 10px;" class="example-accordion" *ngIf="avisosFiltered.length > 1">
                    <cdk-accordion-item #accordionItem="cdkAccordionItem" class="example-accordion-item" role="button"
                        tabindex="0" [attr.id]="'accordion-header-' " [attr.aria-expanded]="accordionItem.expanded"
                        [attr.aria-controls]="'accordion-body-'">
                        <div style="margin-top: 10px; margin-bottom: 15px;"
                            [ngClass]="flag == 1 ? 'alert alert-danger' : 'light'" (click)="accordionItem.toggle()">
                            {{aviso}}
                            <br>
                            <span class="example-accordion-item-description">
                                <small> Click para {{ accordionItem.expanded ? 'cerrar' : 'abrir' }} &nbsp;
                                    <i [style.display]="accordionItem.expanded ? 'none' : ''" class="fa fa-arrow-down"
                                        aria-hidden="true"></i>
                                    <i [style.display]="accordionItem.expanded ? '' : 'none'" class="fa fa-arrow-up"
                                        aria-hidden="true"></i></small>
                            </span>
                        </div>
                        <div class="example-accordion-item-body" role="region"
                            [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-'"
                            [attr.aria-labelledby]="'accordion-header-'">

                            <div id="fade_lg" *ngFor="let item of avisosFiltered; let i = index" [ngClass]="color(item)"
                                class="row d-flex justify-content-center">
                                {{item.descripcion}}
                            </div>

                        </div>
                    </cdk-accordion-item>
                </cdk-accordion>

                <p class="avisoDoc" *ngIf="flagDoc==1">
                    {{avisoDoc}} <small class="linkActu" (click)="openModalCer()">&nbsp;&nbsp;&nbsp;<u>¿Cómo Actualizar
                            certificado?</u></small>
                </p>

            </div>



        </div>

        <div class="d-flex justify-content-center" style="margin-top: .5vh;">
            <h1>¡Bienvenido a Timbranet!</h1>
        </div>

        <div class="row d-flex justify-content-center">
            <p style="text-align: center; color: gray;">Para comenzar a trabajar, seleccione la opción deseada.</p>
        </div>

        <div class="row"><br></div>

        <div class="row d-flex justify-content-center">
            <div class="col-sm-4">
                <button class="card_p glow-on-hover" (click)="checkData()">
                    <img class="imagen_p" src="../../assets/Facturastd.png" loading="lazy" alt="">
                    <h4>FACTURA</h4>
                </button>
            </div>

            <div class="col-sm-4">
                <button class="card_p glow-on-hover" routerLink="/cfdis/misfacturas">
                    <img class="imagen_p" src="../../assets/documents.png" loading="lazy" alt="">
                    <h4 style="margin-top: 5px">MIS CFDI's</h4>
                </button>
            </div>

            <div class="col-sm-4">
                <button class="card_p glow-on-hover" routerLink="/datos/userdata">
                    <img class="imagen_p" src="../../assets/Options2.png" loading="lazy" alt="">
                    <h4>DATOS PERSONALES</h4>
                </button>
            </div>
        </div>

        <div class="row"><br><br><br></div>




    </div>




</div>

<!-- Responsive Celular -->
<div class="content" id="fade_lg" *ngIf="botones==3">

    <div class="row center">

        <div class="row" style="margin-left: 2%; margin-right: 2%;">

            <div *ngIf="avisosFiltered.length == 0" class="aviso_p">
                No existen avisos Recientes
            </div>

            <div *ngIf="avisosFiltered.length == 1">
                <div *ngFor="let i of avisosFiltered" [ngClass]="color(i)">
                    {{i.descripcion}}
                </div>
            </div>

            <cdk-accordion style="margin: 10px;" class="example-accordion" *ngIf="avisosFiltered.length > 1">
                <cdk-accordion-item #accordionItem="cdkAccordionItem" class="example-accordion-item" role="button"
                    tabindex="0" [attr.id]="'accordion-header-' " [attr.aria-expanded]="accordionItem.expanded"
                    [attr.aria-controls]="'accordion-body-'">
                    <div style="margin-top: 10px; margin-bottom: 15px;"
                        [ngClass]="flag == 1 ? 'alert alert-danger' : 'light'" (click)="accordionItem.toggle()">
                        {{aviso}}
                        <br>
                        <span class="example-accordion-item-description">
                            <small> Click para {{ accordionItem.expanded ? 'cerrar' : 'abrir' }} &nbsp;
                                <i [style.display]="accordionItem.expanded ? 'none' : ''" class="fa fa-arrow-down"
                                    aria-hidden="true"></i>
                                <i [style.display]="accordionItem.expanded ? '' : 'none'" class="fa fa-arrow-up"
                                    aria-hidden="true"></i></small>
                        </span>
                    </div>
                    <div class="example-accordion-item-body" role="region"
                        [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-'"
                        [attr.aria-labelledby]="'accordion-header-'">

                        <div id="fade_lg" *ngFor="let item of avisosFiltered; let i = index" [ngClass]="color(item)"
                            class="row d-flex justify-content-center">
                            {{item.descripcion}}
                        </div>

                    </div>
                </cdk-accordion-item>
            </cdk-accordion>

            <p class="avisoDoc" *ngIf="flagDoc==1">
                {{avisoDoc}}
            </p>
        </div>

        <div class="col-xs-10">
            <h3 style="margin-top: 25px;">
                <h1>¡Bienvenido a Timbranet!</h1>
            </h3>
        </div>

        <div class="col-xs-10">
            <p style="text-align: center; color: gray;">Para comenzar a trabajar, seleccione la opción deseada.</p>
        </div>

        <div class="row d-flex justify-content-center">
            <div class="col-sm-4">
                <button class="card_p glow-on-hover" (click)="checkData()">
                    <img class="imagen_p" src="../../assets/Facturastd.png" loading="lazy" alt="">
                    <h4>FACTURA</h4>
                </button>
            </div>

            <div class="col-sm-4">
                <button class="card_p glow-on-hover" routerLink="/cfdis/misfacturas">
                    <img class="imagen_p" src="../../assets/documents.png" loading="lazy" alt="">
                    <h4 style="margin-top: 5px">MIS CFDI's</h4>
                </button>
            </div>

            <div class="col-sm-4">
                <button class="card_p glow-on-hover" routerLink="/datos/userdata">
                    <img class="imagen_p" src="../../assets/Options2.png" loading="lazy" alt="">
                    <h4>DATOS PERSONALES</h4>
                </button>
            </div>


        </div>

        <div class="row"><br></div>


    </div>

</div>



<!-- MODAL CERTIFICADO .CER -->
<div class="row">

    <modal #modalCer>
        <modal-content>
            <h4 style="text-align: center;"><b>¿Cómo subir mi certificado .cer?</b></h4>
            <div class="row d-flex justify-content-center">
                <img class="petImg" src="../../../assets/Guia/facturin3.png" alt="">
            </div>
            <div class="row d-flex justify-content-center">

                <p style="text-align: center;">
                    Para poder subir tu <b>Certificado .cer</b> debes acceder a tus datos personales, puedes acceder
                    desde el menú principal.
                </p>

                <img style="border-radius: 25px;" src="../../../assets/Guia/personalDataimg1.png" alt="">

                <div class="row"><br></div>

                <p style="text-align: center;">
                    O también puedes acceder a <b>Tus datos personales</b> en el menú de navegación
                    principal, seleccionando "Configuración" seguido de
                    "Mis Datos".
                </p>

                <img style="border-radius: 25px;" src="../../../assets/Guia/personalDataimg2.png" alt="">

                <div class="row"><br></div>
                <p style="text-align: center;">
                    Una vez dentro de Tus datos personales, deberás dirigirte al apartado "Certificados .Cer y .Key" y
                    dar Click
                    en el botón <b>Subir Certificados .Cer y .Key </b>
                </p>
                <img style="border-radius: 25px;" src="../../../assets/Guia/Certimg.png" alt="">

                <div class="row"><br></div>
                <p style="text-align: center;">
                    Se abrirá una ventana nueva en la cual deberás introducir tu <b>Archivo de clave privada *.key</b> y
                    su
                    contraseña, seguido de tu <b> archivo
                        *.cer </b>, una vez subidos deberás dar click en el botón "Enviar".
                </p>
                <img style="border-radius: 25px;" src="../../../assets/Guia/Certimg2.png" alt="">

                <div class="row"><br></div>
                <p style="text-align: center;">
                    Para finalizar <b><u> es muy importante que des click al nuevo botón de Actualizar </u></b> para
                    guardar tus
                    cambios.
                </p>
                <img style="border-radius: 25px;" src="../../../assets/Guia/Certimg4.png" alt="">
                <div class="row"><br></div>

            </div>

            <hr>

            <div class="row">
                <div class="col d-flex justify-content-center">
                    <button class="btn btn-danger" (click)="closeModalCer()"><i class="fa fa-arrow-left"
                            aria-hidden="true"></i>&nbsp;&nbsp;Regresar</button>
                </div>

                <div class="col d-flex justify-content-center">
                    <button class="btn btn-primary" (click)="closeModalCer();" routerLink="/datos/userdata">Llévame a
                        ello</button>
                </div>

            </div>

        </modal-content>


    </modal>
</div>