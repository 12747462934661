<div class='contenedora'>

  <!-- Spinner animation general -->
  <div class="loading-overlay" *ngIf="loading">
    <mat-spinner class="spinner" diameter="70"></mat-spinner>
  </div>

  <!-- Menú de Navegacion principal -->
  <app-menu *ngIf="flagMenu"></app-menu>


  <!-- Banner de modo pruebas -->
  <div class="modo d-flex justify-content-center" id="fade_xl" *ngIf="flagPrueba">MODO DE PRUEBAS</div>


  <!-- Carga componentes -->
  <router-outlet></router-outlet>


  <!-- MODAL GUÍAS -->
  <div class="row">
    <modal #myModal2>

      <modal-content>
        <h3 style="text-align: center;"><b>¡Bienvenido a la Guía oficial de Timbranet!</b></h3>

        <div class="row d-flex justify-content-center">
          <img class="petImg" src="../../../assets/Guia/facturin.png" alt="">

          <small style="text-align: center !important;">
            En esta guía podrás consultar cómo interactuar con las funciones más importantes de la
            página. <br><br></small>

          <h5><b>¿Qué deseas consultar?</b></h5>
        </div>

        <div class="row"><br></div>

        <!-- ACCORDION GUIAS GENERALES -->
        <div class="row" style="margin-left: 2%; margin-right: 2%;">
          <cdk-accordion class="accordionGeneral">
            <cdk-accordion-item *ngFor="let item of items; let index = index;" #accordionItem="cdkAccordionItem"
              class="example-accordion-item" role="button" tabindex="0" [attr.id]="'accordion-header-' + index"
              [attr.aria-expanded]="accordionItem.expanded" [attr.aria-controls]="'accordion-body-' + index">
              <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
                <b> {{ item }}</b>
                <br>
                <span class="example-accordion-item-description">
                  - Click para {{ accordionItem.expanded ? 'cerrar' : 'abrir' }} -
                </span>
              </div>
              <div class="example-accordion-item-body" role="region"
                [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
                [attr.aria-labelledby]="'accordion-header-' + index">

                <!-- CONTENIDO DE ACCORDION -->
                <hr>
                <h5><b>Actualizar tus Datos personales</b></h5>
                <div class="row d-flex justify-content-center">
                  <button class="btn btn-primary" style="width: 150px;"
                    (click)="closeModal();openModalData()">Consultar</button>
                </div>

                <hr>
                <h5><b>Subir tu Certificado .cer</b></h5>
                <div class="row d-flex justify-content-center">
                  <button class="btn btn-primary" style="width: 150px;"
                    (click)="closeModal();openModalCer()">Consultar</button>
                </div>

                <hr>
                <h5><b>Relacionar Facturas</b></h5>
                <div class="row d-flex justify-content-center">
                  <button class="btn btn-primary" style="width: 150px;"
                    (click)="closeModal();openModalRelacion()">Consultar</button>
                </div>

                <hr>
                <h5><b>Carga XML en facturas</b></h5>
                <div class="row d-flex justify-content-center">
                  <button class="btn btn-primary" style="width: 150px;"
                    (click)="closeModal();openModalcargaXML()">Consultar</button>
                </div>

                <hr>
                <h5><b>Consultar CFDIs</b></h5>
                <div class="row d-flex justify-content-center">
                  <button class="btn btn-primary" style="width: 150px;"
                    (click)="closeModal();openModalconsultaCFDI()">Consultar</button>
                </div>

                <hr>
                <h5><b>Cancelar CFDIs</b></h5>
                <div class="row d-flex justify-content-center">
                  <button class="btn btn-primary" style="width: 150px;"
                    (click)="closeModal();openModalcancelaCFDI()">Consultar</button>
                </div>


              </div>
            </cdk-accordion-item>
          </cdk-accordion>
        </div>

        <div class="row"><br></div>

        <!-- ACCORDION GUIAS DE TIMBRADO -->
        <div class="row" style="margin-left: 2%; margin-right: 2%;">
          <cdk-accordion class="accordionTimbrado">
            <cdk-accordion-item *ngFor="let item of items2; let index = index;" #accordionItem="cdkAccordionItem"
              class="example-accordion-item" role="button" tabindex="0" [attr.id]="'accordion-header-' + index"
              [attr.aria-expanded]="accordionItem.expanded" [attr.aria-controls]="'accordion-body-' + index">
              <div class="example-accordion-item-header" (click)="accordionItem.toggle()">
                <b> {{ item }}</b>
                <br>
                <span class="example-accordion-item-description">
                  - Click para {{ accordionItem.expanded ? 'cerrar' : 'abrir' }} -
                </span>
              </div>
              <div class="example-accordion-item-body" role="region"
                [style.display]="accordionItem.expanded ? '' : 'none'" [attr.id]="'accordion-body-' + index"
                [attr.aria-labelledby]="'accordion-header-' + index">

                <!-- CONTENIDO DE ACCORDION -->
                <hr>
                <h5><b>Timbrar Factura Estándar</b></h5>
                <div class="row d-flex justify-content-center">
                  <button class="btn btn-primary" style="width: 150px;"
                    (click)="closeModal();openModalFStandard()">Consultar</button>
                </div>

                <hr>
                <h5><b>Timbrar CFDI Traslado CCP</b></h5>
                <div class="row d-flex justify-content-center">
                  <button class="btn btn-primary" style="width: 150px;"
                    (click)="closeModal();openModalCFDIT()">Consultar</button>
                </div>

                <hr>
                <h5><b>Timbrar Factura de Transporte</b></h5>
                <div class="row d-flex justify-content-center">
                  <button class="btn btn-primary" style="width: 150px;"
                    (click)="closeModal();openModalTransporte()">Consultar</button>
                </div>

                <hr>
                <h5><b>Timbrar Documento de Pago</b></h5>
                <div class="row d-flex justify-content-center">
                  <button class="btn btn-primary" style="width: 150px;"
                    (click)="closeModal();openModalPago()">Consultar</button>
                </div>

              </div>
            </cdk-accordion-item>
          </cdk-accordion>
        </div>

        <div class="row"><br></div>
        <div class="row"><hr></div>

        <div class="row d-flex justify-content-center">
          <button style="width: 50px;" class="btn btn-secondary" (click)="closeModal()"><i class="fa fa-arrow-left"
              aria-hidden="true"></i></button>
        </div>

      </modal-content>

    </modal>
  </div>

  <!-- FOOTER -->
  <div class="row d-flex justify-content-end" style="z-index: 2 !important;">
    <i class="fa fa-question-circle tutorialBtn" (click)="openModal2()" aria-hidden="true"></i>
  </div>

  

  <!-- ------------------------ MODALES GENERALES ------------------------ -->

  <!-- MODAL DATOS PERSONALES -->
  <div class="row">

    <modal #modalData>
      <modal-content>
        <h4 style="text-align: center;"><b>¿Cómo actualizar mis datos personales?</b></h4>
        <div class="row d-flex justify-content-center">
          <img class="petImg" src="../../../assets/Guia/facturin3.png" alt="">
        </div>
        <div class="row d-flex justify-content-center">

          <p>
            Existen diversas maneras para acceder a <b>Tus datos personales</b>, la primera de ellas se
            encuentra ubicada en el menú principal.
          </p>

          <img src="../../../assets/Guia/personalDataimg1.png" alt="">
          <div class="row"><br></div>

          <p>
            O también puedes acceder a <b>Tus datos personales</b> en el menú de navegación
            principal, seleccionando "Configuración" seguido de
            "Mis Datos".
          </p>

          <img src="../../../assets/Guia/personalDataimg2.png" alt="">
          <div class="row"><br></div>

          <p>
            Una vez dentro de Tus datos personales, deberás seleccionar el botón "Consultar Datos &nbsp;<i
              class="fa fa-briefcase" aria-hidden="true"></i>"
            para editar los datos personales de tu empresa, es un requisito indispensable para poder facturar.
          </p>

          <img src="../../../assets/Guia/personalDataimg3.png" alt="">
          <div class="row"><br></div>

          <p>
            Deberás llenar los datos obligatorios dentro de las secciones <b> "Datos generales de la Empresa"</b> y
            <b>"Datos Fiscales (XML)"</b>
          </p>

          <img src="../../../assets/Guia/personalDataimg4.png" alt="">
          <div class="row"><br></div>

          <p>
            Al terminar deberás dar click en el botón <b>"Guardar Datos <i class="fa fa-floppy-o"
                aria-hidden="true"></i>"</b>
          </p>

          <img src="../../../assets/Guia/personalDataimg5.png" alt="">

        </div>

        <hr>

        <div class="row">
          <div class="col d-flex justify-content-center">
            <button class="btn btn-danger" (click)="closeModalData();openModal2()"><i class="fa fa-arrow-left"
                aria-hidden="true"></i>&nbsp;&nbsp;Regresar</button>
          </div>

          <div class="col d-flex justify-content-center">
            <button class="btn btn-primary" (click)="closeModalData();" routerLink="/datos/userdata">Llévame a
              ello</button>
          </div>

        </div>

      </modal-content>


    </modal>
  </div>

  <!-- MODAL CERTIFICADO .CER -->
  <div class="row">
    <modal #modalCer>
      <modal-content>
        <h4 style="text-align: center;"><b>¿Cómo subir mi certificado .cer?</b></h4>
        <div class="row d-flex justify-content-center">
          <img class="petImg" src="../../../assets/Guia/facturin3.png" alt="">
        </div>
        <div class="row d-flex justify-content-center">
          <p>
            Para poder subir tu <b>Certificado .cer</b> debes acceder a tus datos personales, puedes acceder
            desde el menú principal.
          </p>

          <img src="../../../assets/Guia/personalDataimg1.png" alt="">
          <div class="row"><br></div>

          <p>
            O también puedes acceder a <b>Tus datos personales</b> en el menú de navegación
            principal, seleccionando "Configuración" seguido de
            "Mis Datos".
          </p>

          <img src="../../../assets/Guia/personalDataimg2.png" alt="">
          <div class="row"><br></div>

          <p>
            Una vez dentro de Tus datos personales, deberás dirigirte al apartado "Certificados .Cer y .Key" y
            dar Click
            en el botón <b>Subir Certificados .Cer y .Key </b>
          </p>

          <img src="../../../assets/Guia/Certimg.png" alt="">
          <div class="row"><br></div>

          <p>
            Se abrirá una ventana nueva en la cual deberás introducir tu <b>Archivo de clave privada *.key</b> y
            su
            contraseña, seguido de tu <b> archivo
              *.cer </b>, una vez subidos deberás dar click en el botón "Enviar".
          </p>

          <img src="../../../assets/Guia/Certimg2.png" alt="">
          <div class="row"><br></div>

          <p>
            Para finalizar <b><u> es muy importante que des click al nuevo botón de Actualizar </u></b> para
            guardar tus
            cambios.
          </p>

          <img src="../../../assets/Guia/Certimg4.png" alt="">
          <div class="row"><br></div>

        </div>

        <hr>

        <div class="row">
          <div class="col d-flex justify-content-center">
            <button class="btn btn-danger" (click)="closeModalCer();openModal2()"><i class="fa fa-arrow-left"
                aria-hidden="true"></i>&nbsp;&nbsp;Regresar</button>
          </div>

          <div class="col d-flex justify-content-center">
            <button class="btn btn-primary" (click)="closeModalCer();" routerLink="/datos/userdata">Llévame a
              ello</button>
          </div>

        </div>

      </modal-content>
    </modal>
  </div>

  <!-- MODAL RELACION FACTURAS -->
  <div class="row">

    <modal #modalRelacion>
      <modal-content>
        <h4 style="text-align: center;"><b>¿Cómo relacionar facturas?</b></h4>

        <div class="row d-flex justify-content-center">
          <img class="petImg" src="../../../assets/Guia/facturin3.png" alt="">
        </div>

        <div class="row d-flex justify-content-center">

          <p>
            Para poder <b>relacionar una factura</b> se debe realizar una factura e indicar en la misma, la relación que
            se tiene con la previamente timbrada. Para ello se mostrará un ejemplo con una factura estándar.
            <br>
          </p>

          <p>
            Primero, debemos realizar una factura, en este caso, accederé a Factura estandar desde el menú principal.
          </p>

          <img src="../assets/Guia/Facturacion/facturaEstandar/Ventas.png" alt="">
          <div class="row"><br></div>

          <p>
            Una vez dentro, en el <b>Paso 1 - Datos Generales</b> nos desplazaremos a la sección inferior del
            formulario, ahí encontraremos una sección <b><u>"Si tu factura tiene alguna relación, indicar aquí"</u></b>,
            al dar click se desplegará un menú con la siguiente información:
          </p>

          <img src="../assets/Guia/Generales/RelacionFactura/fRelacion1.png" alt="">
          <div class="row"><br></div>

          <p>
            Se deberá indicar el tipo de Relación que tiene y el UUID de la factura previamente timbrada a relacionar.
          </p>

          <img src="../assets/Guia/Generales/RelacionFactura/fRelacion2.png" alt="">
          <div class="row"><br></div>

          <p>
            En caso de no tener un UUID Relacionado, se puede buscar de las facturas previamente timbradas en tu cuenta,
            llenando los campos "Serie" y "Folio", si hay una factura con dichos datos, se autocompletará el <b>"UUID
              Relacionado".</b>
          </p>

          <img src="../assets/Guia/Generales/RelacionFactura/fRelacion3.png" alt="">
          <div class="row"><br></div>

          <p>
            Para finalizar se debe dar click en<b><u> "Agregar Relación"</u></b> y se verá reflejado en la tabla
            inferior al botón-
          </p>

          <img src="../assets/Guia/Generales/RelacionFactura/fRelacion4.png" alt="">
          <div class="row"><br></div>

          <p>
            Al terminar de agregar la relación, solo queda continuar desarrollando la factura y timbrarla, las
            relaciones añadidas serán implementadas en el xml y visualmente en el PDF generado.
          </p>

        </div>

        <hr>

        <div class="row">
          <div class="col d-flex justify-content-center">
            <button class="btn btn-danger" (click)="closeModalRelacion();openModal2()"><i class="fa fa-arrow-left"
                aria-hidden="true"></i>&nbsp;&nbsp;Regresar</button>
          </div>
        </div>

      </modal-content>
    </modal>
  </div>

  <!-- MODAL CARGA XML -->
  <div class="row">

    <modal #cargaXML>
      <modal-content>
        <h4 style="text-align: center;"><b>¿Cómo funciona la carga XML?</b></h4>
        <div class="row d-flex justify-content-center">
          <img class="petImg" src="../../../assets/Guia/facturin3.png" alt="">
        </div>
        <div class="row d-flex justify-content-center">

          <p>
            La <b>Carga XML</b> es una herramienta muy útil que nos ahorra trabajo, en especial cuando debemos realizar
            una factura con los mismos datos varias veces.
            <br>
          </p>

          <p>
            Permite que, a través de un XML ya formado por nuestra aplicación, se puedan extraer los datos
            y completar la mayoría de los campos en el formulario de una factura rápidamente.
            <br>
          </p>

          <p>
            Primero, debemos realizar una factura, en este caso, accederé a Factura estandar desde el menú principal.
          </p>

          <img src="../assets/Guia/Facturacion/facturaEstandar/Ventas.png" alt="">
          <div class="row"><br></div>

          <p>
            Una vez dentro, en la parte posterior del formulario encontraremos un ícono especial <i
              class="fa fa-arrow-circle-o-up" aria-hidden="true"></i>
            que nos permitirá cargar nuestro XML.
            <br>
          </p>

          <img src="../assets/Guia/Generales/CargaXML/cargaXML1.png" alt="">

          <p>
            Al dar click en el ícono se desplegará una pequeña sección en la cuál deberemos subir nuestro XML
            previamente obtenido <i class="fa fa-arrow-circle-o-up" aria-hidden="true"></i>
            que nos permitirá cargar nuestro XML.
            <br>
          </p>

          <p>
            Primero se deberá dar click en <b>"Elegir Archivo"</b> y seleccionar el XML a cargar. Una vez cargado se
            deberá dar click al botón <b>"Cargar Documento"</b> para que quede correctamente añadido y se cargue la
            información.
            <br>
          </p>

          <img src="../assets/Guia/Generales/CargaXML/cargaXML2.png" alt="">
          <div class="row"><br></div>

          <p>
            Al subir el XML correctamente notará que los campos de la factura habrán sido llenados, solo faltará
            verificar que la información de la factura este correcta y timbrar.
            <br>
          </p>

        </div>

        <hr>

        <div class="row">
          <div class="col d-flex justify-content-center">
            <button class="btn btn-danger" (click)="closeModalcargaXML();openModal2()"><i class="fa fa-arrow-left"
                aria-hidden="true"></i>&nbsp;&nbsp;Regresar</button>
          </div>
        </div>

      </modal-content>
    </modal>
  </div>

  <!-- MODAL CONSULTA DE CFDIS -->
  <div class="row">

    <modal #consultaCFDI>
      <modal-content>
        <h4 style="text-align: center;"><b>¿Cómo consultar mis CFDI?</b></h4>
        <div class="row d-flex justify-content-center">
          <img class="petImg" src="../../../assets/Guia/facturin3.png" alt="">
        </div>
        <div class="row d-flex justify-content-center">

          <p>
            Para <b>Consultar CFDIs</b> previamente timbrados debemos dirigirnos en la sección principal de la página
            en la tarjeta de <b>"MIS CFDI's"</b> y dar click en ella.
            <br>
          </p>

          <img src="../assets/Guia/Generales/misCfdis/cfdis1.png" alt="">
          <div class="row"><br></div>

          <p>
            Una vez dentro, se desplegará una ventana donde se mostrarán las facturas timbradas previamente, así como
            varios botones para poder previsualizarlas, cancelarlas y descargarlas.
            <br>
          </p>

          <img src="../assets/Guia/Generales/misCfdis/cfdis2.png" alt="">

          <p>
            <br>
            Para poder interactuar con cualquier factura disponible, debemos dar click sobre ella y seleccionarla.
            <br>
          </p>

          <img style="width: 85%;" src="../assets/Guia/Generales/misCfdis/cfdis9.png" alt="">
          <div class="row"><br></div>

          <p>
            El primer elemento que podemos encontrar es el <b>"Filtro"</b> que nos ayudará a buscar en la tabla las
            facturas timbradas, se pueden buscar por cualquier valor, UUID, fecha, serie, total, estatus o tipo.
            <br>
          </p>

          <img style="width: 70%;" src="../assets/Guia/Generales/misCfdis/cfdis3.png" alt="">
          <div class="row"><br></div>

          <p>
            Enseguida encontraremos varios botones que nos ayudarán a gestionar nuestras facturas.
            <br><br>
            El primer botón <i class="fa fa-file-pdf-o" aria-hidden="true"></i> al ser seleccionado abrirá una
            previsualización PDF de la factura seleccionada.
            <br>
          </p>

          <img style="width: 90%;" src="../assets/Guia/Generales/misCfdis/cfdis4.png" alt="">

          <p>
            <br>
            El siguiente botón <i class="fa fa-file-text" aria-hidden="true"></i> al ser seleccionado descargará el XML
            correspondiente a la factura seleccionada.
            <br>
          </p>

          <img style="width: 90%;" src="../assets/Guia/Generales/misCfdis/cfdis5.png" alt="">

          <p>
            <br>
            El botón <i class="fa fa-file-excel-o" aria-hidden="true"></i> al ser seleccionado descargará un archivo
            XLSX (Tabla de Excel) con los datos actuales de la tabla de CFDI's.
            <br>
          </p>

          <img style="width: 90%;" src="../assets/Guia/Generales/misCfdis/cfdis6.png" alt="">

          <p>
            <br>
            El cuarto botón <i class="fa fa-times" aria-hidden="true"></i> al ser seleccionado desplegará una ventana
            que nos ayudará a
            cancelar la factura seleccionada. <small style="color: rgb(57, 57, 255); cursor: pointer;"
              (click)="closeModalconsultaCFDI();openModalcancelaCFDI()">¿Cómo cancelar una factura?</small>
            <br>
          </p>

          <img style="width: 85%;" src="../assets/Guia/Generales/misCfdis/cfdis7.png" alt="">

          <p>
            <br>
            Por último el botón "Cargar Más" cargará más elementos en la tabla, en el caso de tener más de 50 cfdi's, ya
            que la tabla solo cargará las ultimas 50 facturas timbradas al entrar.
            <br>
          </p>

          <img style="width: 85%;" src="../assets/Guia/Generales/misCfdis/cfdis8.png" alt="">
          <div class="row"><br></div>

          <hr>
          <p><b>Extra</b></p>
          <p>Existe un botón adicional que es exclusivo de <b>"Facturas de Transporte"</b>, al seleccionar cualquier
            factura de transporte de la tabla, se mostrará el botón &nbsp;<i class="fa fa-file" aria-hidden="true"></i>.
          </p>

          <img style="width: 75%;" src="../assets/Guia/Generales/misCfdis/cfdis10.png" alt="">
          <div class="row"><br></div>

          <p>Dicho botón nos ayudará a consultar el PDF de nuestro CFDI con <u>totales en 0 (cero).</u></p>
        </div>

        <hr>

        <div class="row">
          <div class="col d-flex justify-content-center">
            <button class="btn btn-danger" (click)="closeModalconsultaCFDI();openModal2()"><i class="fa fa-arrow-left"
                aria-hidden="true"></i>&nbsp;&nbsp;Regresar</button>
          </div>
        </div>

      </modal-content>
    </modal>
  </div>

  <!-- MODAL CANCELACIÓN DE CFDIS -->
  <div class="row">

    <modal #cancelaCFDI>
      <modal-content>
        <h4 style="text-align: center;"><b>¿Cómo cancelar un CFDI?</b></h4>

        <div class="row d-flex justify-content-center">
          <img class="petImg" src="../../../assets/Guia/facturin3.png" alt="">
        </div>

        <div class="row d-flex justify-content-center">

          <p>
            Para <b>Cancelar CFDIs</b> previamente timbrados debemos dirigirnos en la sección principal de la página
            en la tarjeta de <b>"MIS CFDI's"</b> y dar click en ella.
            <br>
          </p>

          <img src="../assets/Guia/Generales/misCfdis/cfdis1.png" alt="">
          <div class="row"><br></div>

          <p>
            Una vez dentro, se desplegará una ventana donde se mostrarán las facturas timbradas previamente, así como
            varios botones para poder previsualizarlas, cancelarlas y descargarlas.
            <br>
          </p>

          <img src="../assets/Guia/Generales/misCfdis/cfdis2.png" alt="">

          <p>
            <br>
            Para poder interactuar con cualquier factura disponible, debemos dar click sobre ella y seleccionarla.
            <br>
          </p>

          <img style="width: 85%;" src="../assets/Guia/Generales/misCfdis/cfdis9.png" alt="">
          <div class="row"><br></div>

          <p>
            <br>
            Para cancelar un cfdi previamente timbrado debemos <u>seleccionar la factura y dar click en el botón <i
                class="fa fa-times" aria-hidden="true"></i></u>
            <br>
          </p>

          <div class="row"><br></div>
          <img style="width: 85%;" src="../assets/Guia/Generales/misCfdis/cfdis7.png" alt="">

          <p><br> Se desplegará una ventana
            que nos ayudará con el proceso de cancelación.</p>

          <div class="row"><br></div>
          <img style="width: 85%;" src="../assets/Guia/Generales/misCfdis/cancela1.png" alt="">

          <p>
            <br>
            En la ventana se deberá seleccionar el motivo de la cancelación, al seleccionarlo se mostrará una
            descripción del uso de ese motivo.
            <br>
          </p>

          <img style="width: 85%;" src="../assets/Guia/Generales/misCfdis/cancela2.png" alt="">
          <div class="row"><br></div>

          <p>
            Una vez seleccionado el motivo dar click en <b>"Cancelar CFDI"</b>, seguido de esto la factura estará en
            proceso <b style="color: rgb(193, 132, 0);">E</b> - Espera
            <br>
          </p>

          <p>
            Una vez en este estado el CFDI puede tardar hasta 72 horas en cancelar, puede checar el estatus de su
            factura en cualquier momento desde un nuevo botón que se habilita para facturas en proceso.
            <br>
          </p>

          <p>
            El botón <i class="fa fa-clock-o" aria-hidden="true"></i> nos ayudará a saber el estado de la factura
            (recuerde que el botón <u>solo aparecerá al seleccionar facturas en estado de Espera o Canceladas</u>)
            <br>
          </p>

          <img style="width: 85%;" src="../assets/Guia/Generales/misCfdis/cancela4.png" alt="">
          <div class="row"><br></div>

          <p>
            Puede verificar periódicamente el estado de su CFDI dando click en el botón de "Actualizar <i
              class="fa fa-refresh" aria-hidden="true"></i>" de la ventana
            Estado de factura, se mostrará el UUID del CFDI y su actual estado.
            <br>
          </p>

          <img style="width: 85%;" src="../assets/Guia/Generales/misCfdis/cancela5.png" alt="">
          <div class="row"><br></div>

          <p>
            Una vez cancelado el CFDI se mostrará en la tabla en color rojo y su estatus habrá cambiado a <b
              style="color: rgba(197, 2, 2, 0.585);">"C"</b>
            <br>
          </p>

          <img style="width: 85%;" src="../assets/Guia/Generales/misCfdis/cancela6.png" alt="">
          <div class="row"><br></div>

        </div>

        <hr>

        <div class="row">
          <div class="col d-flex justify-content-center">
            <button class="btn btn-danger" (click)="closeModalcancelaCFDI();openModal2()"><i class="fa fa-arrow-left"
                aria-hidden="true"></i>&nbsp;&nbsp;Regresar</button>
          </div>
        </div>

      </modal-content>
    </modal>
  </div>





  <!-- ------------------------ MODALES FACTURACIÓN ------------------------ -->


  <!-- MODAL FACTURA ESTÁNDAR -->
  <div class="row">

    <modal #modalFStandard>
      <modal-content>

        <h4 style="text-align: center;"><b>¿Cómo realizar una factura estándar?</b></h4>

        <div class="row d-flex justify-content-center">
          <img class="petImg" src="../../../assets/Guia/facturin3.png" alt="">
        </div>

        <div class="row d-flex justify-content-center">


          <p>
            Para poder realizar una <b>Factura estándar</b> debes acceder a su apartado desde el menú principal, dando
            click en el apartado <b>"Ventas"</b> seguido de <b>"Factura / Cargo / Crédito"</b>
          </p>

          <img src="../assets/Guia/Facturacion/facturaEstandar/Ventas.png" alt="">

          <div class="row"><br></div>
          <p>
            O también puedes acceder a la <b>Factura estándar</b> desde la sección principal de la página
            dando click en la tarjeta de <b>"FACTURA"</b>
          </p>

          <img src="../assets/Guia/Facturacion/facturaEstandar/facturaEstandar1.png" alt="">


          <div class="row"><br></div>

          <p>
            Una vez dentro se mostrará un formulario en el cuál <u>se deberán seguir y llenar los datos en los pasos
              indicados</u>.
          </p>

          <img src="../assets/Guia/Facturacion/facturaEstandar/facturaEstandar2.png" alt="">

          <div class="row"><br></div>

          <p>
            Es de suma importancia llenar correctamente los datos obligatorios, marcados con un <b>*</b>.
          </p>

          <img style=" width: 60% !important;" src="../assets/Guia/Facturacion/facturaEstandar/facturaEstandar3.png"
            alt="">

          <div class="row"><br></div>

          <p>
            Al llenar el primer campo <b>Facturar A</b>, se autocompletarán los campos <b>"Nombre", "RFC", "Método de
              Pago",
              "Forma de Pago", "Uso de CFDI", "Condiciones de Pago"</b> con los datos del cliente
            seleccionado.
          </p>

          <img src="../assets/Guia/Facturacion/facturaEstandar/facturaEstandar4.png" alt="">

          <div class="row"><br></div>

          <p style="color: gray; text-align: center;"><small><u>Si no se tiene cliente para seleccionar, debe crearlo
                previamente.</u></small></p>

          <div class="row"><br></div>

          <p>
            En el <b>Paso 2 - Conceptos</b> se deberá seleccionar un Artículo, al seleccionarlo se autocompletarán los
            campos <b>"Unidad", "Cantidad", "Precio Unitario", "Tipo Descuento", "Descuento", "Pedimento (en el caso de
              tener)"</b>, para agregarlo finalmente se debe dar click en el botón <b>"Agregar"</b>.
          </p>

          <img src="../assets/Guia/Facturacion/facturaEstandar/facturaEstandar5.png" alt="">

          <p style="color: gray; text-align: center;"><small><u>Si no se tienen Conceptos para seleccionar, debe
                crearlos previamente.</u></small></p>

          <div class="row"><br></div>

          <p>
            Al agregar con exito un "Concepto" se verán reflejados debajo del mismo botón los totales que el concepto
            añadió, así como el concepto mismo en la tabla
          </p>

          <img src="../assets/Guia/Facturacion/facturaEstandar/facturaEstandar6.png" alt="">

          <div class="row"><br></div>

          <p>
            En el caso de que se desee eliminar el concepto agregado, se puede hacer dando click a la <i
              class="fa fa-times" aria-hidden="true"></i> que se encuentra en el mismo concepto de la tabla.
          </p>

          <img src="../assets/Guia/Facturacion/facturaEstandar/facturaEstandar7.png" alt="">


          <div class="row"><br></div>
          <p>
            En el <b> Paso 3 </b>, solo queda dar click en el botón <b>"TIMBRAR"</b>, el sistema verificará los datos y
            se
            desplegará un mensaje de confirmación, o error en el caso de que algo esté mal.
          </p>

          <img src="../assets/Guia/Facturacion/facturaEstandar/facturaEstandar8.png" alt="">

          <div class="row"><br></div>

          <p>
            El mensaje de confirmación tendrá tres opciones disponibles
            <b>"Sí, Timbrar"</b>,
            <b>"Previsualizar"</b> y
            <b>"Cancelar"</b>.
          </p>

          <img style=" width: 80% !important;" src="../assets/Guia/Facturacion/facturaEstandar/facturaEstandar9.png"
            alt="">

          <div class="row"><br></div>

          <p style=" text-align: center;">
            <b style="color: rgb(42, 93, 233);">"Sí,
              Timbrar: "</b><br> Timbrará la factura. <br><br>
            <b style="color: green">"Previsualizar: "</b><br>Abrirá una nueva ventana en la cual se podrá previsualizar
            el
            PDF de la factura.<br><br>
            <b style="color: rgb(226, 19, 19);">"Cancelar: "</b><br> Regresará al menú anterior al timbrado. <br><br>
          </p>
        </div>

        <hr>

        <div class="row">
          <div class="col d-flex justify-content-center">
            <button class="btn btn-danger" (click)="closeModalFStandard();openModal2()"><i class="fa fa-arrow-left"
                aria-hidden="true"></i>&nbsp;&nbsp;Regresar</button>
          </div>

        </div>

      </modal-content>
    </modal>
  </div>

  <!-- MODAL CFDI TRASLADO -->
  <div class="row">

    <modal #modalCFDIT>
      <modal-content>
        <h4 style="text-align: center;"><b>¿Cómo realizar un CFDI de Traslado CCP?</b></h4>
        <div class="row d-flex justify-content-center">
          <img class="petImg" src="../../../assets/Guia/facturin3.png" alt="">
        </div>
        <div class="row d-flex justify-content-center">

          <p>
            Para poder realizar un <b>CFDI de Traslado</b> accederemos al menú de ventas
            y enseguida en el submenú seleccionaremos <b> CFDI Traslado CCP</b>
          </p>

          <img src="../assets/Guia/Facturacion/facturaCFDIT/ventas_CCP.png" alt="">

          <div class="row"><br></div>

          <p>
            Este Menú está separado en 6 pasos<b> Datos Generales, Conceptos, Origen y Destino, Productos, Asignación y
              Listo!</b>
          </p>
          <img src="../assets/Guia/Facturacion/facturaCFDIT/Menu_CPP.png" alt="">
          <div class="row"><br></div>
          <hr>
          <p>
            <b>1. Datos Generales</b><br><br> Menú donde se visualizarán tus datos <b>Nombre, RFC, Serie, Observaciones
              y
              Relacionar Factura. </b><br><br>
            <b>Nombre</b> y <b>RFC</b> se llenarán en automático de los datos guardados en la sección de datos
            personales,
            <b>Serie</b> es donde seleccionaremos la serie de tipo CFDI de traslado que identificara nuestro documento.
            <br>
          </p>

          <img src="../assets/Guia/Facturacion/facturaCFDIT/DatosGen.png" alt="">

          <p>
            <br><b>Observaciones</b> es un apartado para incluir las observaciones al CFDI, información adicional al PDF
            que no es necesaria para el Timbrado.
          </p>

          <img src="../assets/Guia/Facturacion/facturaCFDIT/observaciones.png" alt="">

          <div class="row"><br></div>

          <hr>
          <p>
            <b>2. Conceptos</b><br><br>En esta sección se agregarán los conceptos de nuestro CFDI de traslado, en el
            cuál
            tendremos que llenar <b>Articulo, Unidad, Cantidad</b>.<br><br>

            Se tendrá que seleccionar el <b>Concepto</b> de la lista de conceptos agregados. Al seleccionar uno de estos
            se completará la <b>Unidad</b> de medida la cual será editable en cualquier momento.
            <br><br>
            El tercer elemento es
            la <b>Cantidad</b>
            En la cual pondremos la cantidad del concepto seleccionado antes de agregarlo a la tabla de conceptos abajo.

          </p>
          <img src="../assets/Guia/Facturacion/facturaCFDIT/Menu_ccp_conceptos.png" alt="">

          <div class="row"><br></div>
          <p>
            La tabla representa los conceptos que tendrá su CFDI en la sección de conceptos, <u>no confundir con los
              productos
              que el viaje contendrá</u> (Ejemplo: Concepto. Transporte de Productos animales, Productos. filetes,
            huevos,
            etc.).<br><br>

            <b>**Nota Importante**</b> los productos que aparezcan son los que comparten la clave SAT con el concepto
            seleccionado, dado que en este tipo de documento
            deben compartir clave SAT por reglas del complemento Carta Porte.
            <br>
          </p>
          <img src="../assets/Guia/Facturacion/facturaCFDIT/menu_ccp_coceptos_menu_lleno.png" alt="">

          <div class="row"><br></div>
          <hr>
          <p>
            <b>3. Origen y Destino </b><br><br>En este apartado se configura el origen y destino del viaje,
            en esta ventana se encuentran 3 elementos iniciales <b>Remitente, Recoger En, Salida de Cargar Estimada</b>,
            cuando se cargue al menos un destino se agregará un cuarto elemento. <br>
          </p>

          <img src="../assets/Guia/Facturacion/facturaCFDIT/menu_ccp_origen_destino.png" alt="">
          <div class="row"><br></div>

          <p style="text-align: justify;">
            En este menú seleccionaremos el nombre del origen, seguido de la localización cargada previamente
            en el menú clientes.
            <br><br><b>Remitente</b>
            obtendrá el nombre del origen y el segundo elemento <b>Recoger En</b> obtendrá la dirección. Esto tiene
            la finalidad de que el emisor pueda ser uno y que el punto de recolección pueda ser en plazas diferentes.
            <br><br>
            Por último <b>Salida de Cargar Estimada</b> es la fecha aproximada de la salida del viaje, una vez
            seleccionada la
            indicada daremos agregar lo cual lo enlistara como origen en la tabla de abajo.
          </p>

          <img src="../assets/Guia/Facturacion/facturaCFDIT/menu_ccp_origen_destino_destinoss.png" alt="">
          <div class="row"><br></div>

          <p>
            Una vez agregado el origen los elementos de antes mencionados cambiaran para Agregar los Destinos.
            <b>Destinatario, Entregar En, Llegada Estimada, Distancia en Km.</b><br>

            <br><b>*Destinatario: </b> obtendrá
            el nombre del Destino.
            <br><b>*Entregar En: </b> obtendrá la dirección.
            <br><b>*Llegada Estimada: </b> es la fecha aproximada de llegada al destino o los destinos del viaje.
            <br><b>*Distancia en Km: </b> es la distancia aproximada del origen o destino al siguiente destino en la
            lista.
          </p>
          Al final de esto daremos agregar y enlistara el o los destinos que tengan nuestro viaje.
          <div class="row"><br></div>


          <img src="../assets/Guia/Facturacion/facturaCFDIT/menu_ccp_origen_destino_destinos2.png" alt="">
          <div class="row"><br></div>

          <hr>

          <p>
            <b>4. Productos</b> <br><br>En este apartado se debe cargar el contenido (productos) de nuestro viaje.
            Encontraremos:<br><br>

            <b>*Cantidad: </b> cantidad del producto seleccionado.<br>

            <b>*Producto: </b> ontenido de la lista de productos validos en base al concepto seleccionado,
            al seleccionarlo autocomplementará <b>Medida, Peso Kg, Pedimento</b> en el caso de tener esta información
            completa. <br><br>
            Al finalizar le daremos clic en <b>"Agregar"</b> y se enlistara en la tabla que serán los productos del
            CFDI.
          </p>
          <img src="../assets/Guia/Facturacion/facturaCFDIT/menu_ccp_productos.png" alt="">
          <div class="row"><br></div>

          <hr>
          <p>
            <b>5. Asignación</b><br><br>
            En este apartado se debe cargar el operador y vehículo de nuestro viaje, los campos a considerar son los
            siguientes:<br>
            <br><b>*Tractor: </b>Se selecciona el vehículo de nuestro viaje.<br>

            <b>*Remolque y Remolque2: </b>se selecciona 1 o 2 remolques en el caso de ser utilizado en conjunto al
            vehículo.<br>

            <b>*Operador: </b> se selecciona el operador de nuestro viaje.<br>
            <b>*Configuración Autotransporte: </b> se selecciona la configuración de vehículo y remolque más adecuado
            con
            los datos seleccionados.<br>
          </p>
          <img src="../assets/Guia/Facturacion/facturaCFDIT/menu_ccp_asignacion.png" alt="">
          <div class="row"><br></div>


          <div class="row"><br></div>

          <hr>
          <p>
            <b>6. Listo!</b><br><br>
            En este paso solo queda dar click en el botón <b>"TIMBRAR"</b>, el sistema verificará los
            datos y
            se
            desplegará un mensaje de confirmación, o error en el caso de que algo esté mal.
          </p>

          <img src="../assets/Guia/Facturacion/facturaEstandar/facturaEstandar8.png" alt="">

          <div class="row"><br></div>

          <p>
            El mensaje de confirmación tendrá tres opciones disponibles
            <b>"Sí, Timbrar"</b>,
            <b>"Previsualizar"</b> y
            <b>"Cancelar"</b>.
          </p>

          <img style=" width: 80% !important;" src="../assets/Guia/Facturacion/facturaEstandar/facturaEstandar9.png"
            alt="">

          <div class="row"><br></div>

          <p style=" text-align: center;">
            <b style="color: rgb(42, 93, 233);">"Sí,
              Timbrar: "</b><br> Timbrará la factura. <br><br>
            <b style="color: green">"Previsualizar: "</b><br>Abrirá una nueva ventana en la cual se podrá previsualizar
            el
            PDF de la factura.<br><br>
            <b style="color: rgb(226, 19, 19);">"Cancelar: "</b><br> Regresará al menú anterior al timbrado.
          </p>
        </div>

        <hr>

        <div class="row">
          <div class="col d-flex justify-content-center">
            <button class="btn btn-danger" (click)="closeModalCFDIT();openModal2()"><i class="fa fa-arrow-left"
                aria-hidden="true"></i>&nbsp;&nbsp;Regresar</button>
          </div>

        </div>

      </modal-content>


    </modal>
  </div>

  <!-- MODAL FACTURA TRANSPORTE -->
  <div class="row">

    <modal #modalTransporte>
      <modal-content>
        <h4 style="text-align: center;"><b>¿Cómo realizar una Factura de Transporte?</b></h4>
        <div class="row d-flex justify-content-center">
          <img class="petImg" src="../../../assets/Guia/facturin3.png" alt="">
        </div>
        <div class="row d-flex justify-content-center">

          <p>
            Para poder realizar una <b>Factura de Transporte</b> accederemos al menú de ventas
            y enseguida en el submenú seleccionaremos <b> Factura de Transporte</b>
          </p>

          <img src="../assets/Guia/Facturacion/facturaTransporte/menuFT.png" alt="">

          <div class="row"><br></div>

          <p>
            Este Menú está separado en 6 pasos<b> Datos Generales, Conceptos, Origen y Destino, Productos, Asignación y
              Listo!</b>
          </p>
          <img src="../assets/Guia/Facturacion/facturaTransporte/Transporte1.png" alt="">
          <div class="row"><br></div>
          <hr>
          <p>
            <b>1. Datos Generales</b><br><br>
            En este menú se deberán llenar los campos con la información del receptor de la factura.
            <br>
          </p>
          <p>Al introducir el cliente en el campo <b>"Facturar A"</b> se llenarán automáticamente los campos
            <b>Nombre, *RFC, Método de Pago, Forma de Pago, Uso de CFDI</b> y <b>Condiciones de Pago</b>.
          </p>
          <small style="text-align: center !important; color: gray;">Se deberá haber creado un cliente previamente
            <br><br></small>

          <img src="../assets/Guia/Facturacion/facturaTransporte/Transporte2.png" alt="">

          <p>
            <br><b>Observaciones</b> es un apartado para incluir las observaciones generales a la factura, información
            adicional al PDF
            que no es necesaria para el Timbrado.
          </p>

          <img src="../assets/Guia/Facturacion/facturaCFDIT/observaciones.png" alt="">

          <div class="row"><br></div>

          <hr>
          <p>
            <b>2. Conceptos</b><br><br>En esta sección se agregarán los conceptos de nuestra Factura de Transporte, en
            el
            cuál
            tendremos que llenar <b>Concepto, Unidad, Cantidad, Precio Unitario</b> y <b>Descuento</b>.<br><br>

            Se tendrá que seleccionar el <b>Concepto</b> de la lista de conceptos agregados. Al seleccionar uno de estos
            se completará la <b>Unidad</b> de medida, la cual será editable en cualquier momento.
            <br><br>
            El tercer elemento es
            la <b>Cantidad</b>
            En la cual pondremos la cantidad del concepto seleccionado antes de agregarlo a la tabla de conceptos abajo.

            <br><br>
            <b>Precio Unitario</b>
            hace referencia al precio que el concepto seleccionado tendrá.

            <br><br>
            <b>Descuento</b>
            indíca el descuento al precio que el concepto seleccionado tendrá, el descuento puede ser por porcentaje %,
            o por cantidad, dependiendo de la selección en el campo "Tipo Descuento", si el concepto no tiene descuento
            dejar indicado en 0.
            <br><br>
          </p>

          <img src="../assets/Guia/Facturacion/facturaTransporte/Transporte3.png" alt="">

          <div class="row"><br></div>
          <p>
            La tabla representa los conceptos que tendrá su factura en la sección de conceptos, <u>no confundir con los
              productos
              que el viaje contendrá</u> (Ejemplo: Concepto. Transporte de Productos animales, Productos. filetes,
            huevos,
            etc.).<br><br>

            <br>
          </p>
          <img src="../assets/Guia/Facturacion/facturaTransporte/Transporte4.png" alt="">

          <div class="row"><br></div>
          <hr>
          <p>
            <b>3. Origen y Destino </b><br><br>
            En este apartado se configura el origen, destino y tipo del viaje.
            <br><br>
            El primer campo a considerar es el "Tipo de Viaje" en el cuál se indicará el tipo de viaje que tendrá
            nuestra factura ya sea <b>"Nacional"</b>, de <b>"Importación"</b> o de <b>"Exportación"</b>
          </p>

          <div class="row"><br></div>

          <img style=" width: 85% !important;" src="../assets/Guia/Facturacion/facturaTransporte/Transporte5.png"
            alt="">
          <div class="row"><br></div>

          <p>
            En el caso de que el tipo del viaje sea de <b>Importación</b>, se tendrá que indicar el "Régimen Aduanero" y
            el "País" de Importación.
            <br><br>
            <img style=" width: 95% !important;" src="../assets/Guia/Facturacion/facturaTransporte/Transporte6.png"
              alt="">

            <br><br>
            En el caso de que el tipo del viaje sea de <b>Exportación</b>, se tendrá que indicar el "Régimen Aduanero",
            el "País" de Importación y "UUID de Comercio Exterior".
            <br><br>
            <img style=" width: 95% !important;" src="../assets/Guia/Facturacion/facturaTransporte/Transporte7.png"
              alt="">
            <br><br>

            Por último se deberá indicar si tiene o no <b>"Logística Inversa Recolección/Devolución"</b>, si no tiene,
            dejar sin llenar el campo.
            <br><br>
            <img style=" width: 95% !important;" src="../assets/Guia/Facturacion/facturaTransporte/Transporte8.png"
              alt="">
            <br><br>
          </p>


          <p>
            A continuación en el apartado de <b>"Origen/Destino"</b> se encuentran 3 elementos iniciales <b>Remitente,
              Recoger En, Salida de Cargar
              Estimada</b>,
            cuando se cargue al menos un destino se agregará un cuarto elemento. <br>
          </p>

          <img src="../assets/Guia/Facturacion/facturaCFDIT/menu_ccp_origen_destino.png" alt="">
          <div class="row"><br></div>

          <p style="text-align: justify;">
            En este menú seleccionaremos el nombre del origen, seguido de la localización cargada previamente
            en el menú clientes.
            <br><br><b>Remitente</b>
            obtendrá el nombre del origen y el segundo elemento <b>Recoger En</b> obtendrá la dirección. Esto tiene
            la finalidad de que el emisor pueda ser uno y que el punto de recolección pueda ser en plazas diferentes.
            <br><br>
            Por último <b>Salida de Cargar Estimada</b> es la fecha aproximada de la salida del viaje, una vez
            seleccionada la
            indicada daremos agregar lo cual lo enlistara como origen en la tabla de abajo.
          </p>

          <img src="../assets/Guia/Facturacion/facturaCFDIT/menu_ccp_origen_destino_destinoss.png" alt="">
          <div class="row"><br></div>

          <p>
            Una vez agregado el origen los elementos de antes mencionados cambiaran para Agregar los Destinos.
            <b>Destinatario, Entregar En, Llegada Estimada, Distancia en Km.</b><br>

            <br><b>*Destinatario: </b> obtendrá
            el nombre del Destino.
            <br><b>*Entregar En: </b> obtendrá la dirección.
            <br><b>*Llegada Estimada: </b> es la fecha aproximada de llegada al destino o los destinos del viaje.
            <br><b>*Distancia en Km: </b> es la distancia aproximada del origen o destino al siguiente destino en la
            lista.
          </p>
          Al final de esto daremos agregar y enlistara el o los destinos que tengan nuestro viaje.
          <div class="row"><br></div>


          <img src="../assets/Guia/Facturacion/facturaCFDIT/menu_ccp_origen_destino_destinos2.png" alt="">
          <div class="row"><br></div>

          <hr>

          <p>
            <b>4. Productos</b> <br><br>En este apartado se debe cargar el contenido (productos) de nuestro viaje.
            Encontraremos:<br><br>

            <b>*Cantidad: </b> cantidad del producto seleccionado.<br>

            <b>*Producto: </b> ontenido de la lista de productos validos en base al concepto seleccionado,
            al seleccionarlo autocomplementará <b>Medida, Peso Kg, Pedimento</b> en el caso de tener esta información
            completa. <br><br>
            Al finalizar le daremos clic en <b>"Agregar"</b> y se enlistara en la tabla que serán los productos del
            CFDI.
          </p>
          <img src="../assets/Guia/Facturacion/facturaCFDIT/menu_ccp_productos.png" alt="">
          <div class="row"><br></div>

          <hr>
          <p>
            <b>5. Asignación</b><br><br>
            En este apartado se debe cargar el operador y vehículo de nuestro viaje, los campos a considerar son los
            siguientes:<br>
            <br><b>*Tractor: </b>Se selecciona el vehículo de nuestro viaje.<br>

            <b>*Remolque y Remolque2: </b>se selecciona 1 o 2 remolques en el caso de ser utilizado en conjunto al
            vehículo.<br>

            <b>*Operador: </b> se selecciona el operador de nuestro viaje.<br>
            <b>*Configuración Autotransporte: </b> se selecciona la configuración de vehículo y remolque más adecuado
            con
            los datos seleccionados.<br>
          </p>
          <img src="../assets/Guia/Facturacion/facturaCFDIT/menu_ccp_asignacion.png" alt="">
          <div class="row"><br></div>


          <div class="row"><br></div>

          <hr>
          <p>
            <b>6. Listo!</b><br><br>
            En este paso solo queda dar click en el botón <b>"TIMBRAR"</b>, el sistema verificará los
            datos y
            se
            desplegará un mensaje de confirmación, o error en el caso de que algo esté mal.
          </p>

          <img src="../assets/Guia/Facturacion/facturaEstandar/facturaEstandar8.png" alt="">

          <div class="row"><br></div>

          <p>
            El mensaje de confirmación tendrá tres opciones disponibles
            <b>"Sí, Timbrar"</b>,
            <b>"Previsualizar"</b> y
            <b>"Cancelar"</b>.
          </p>

          <img style=" width: 80% !important;" src="../assets/Guia/Facturacion/facturaEstandar/facturaEstandar9.png"
            alt="">

          <div class="row"><br></div>

          <p style=" text-align: center;">

            <b style="color: rgb(42, 93, 233);">"Sí,
              Timbrar: "</b><br> Timbrará la factura. <br><br>

            <b style="color: green">"Previsualizar: "</b><br>Abrirá una nueva ventana en la cual se podrá previsualizar
            el
            PDF de la factura.<br><br>

            <b style="color: rgb(226, 19, 19);">"Cancelar: "</b><br> Regresará al menú anterior al timbrado.

          </p>


        </div>

        <hr>

        <div class="row">
          <div class="col d-flex justify-content-center">
            <button class="btn btn-danger" (click)="closeModalTransporte();openModal2()"><i class="fa fa-arrow-left"
                aria-hidden="true"></i>&nbsp;&nbsp;Regresar</button>
          </div>
        </div>
      </modal-content>
    </modal>
  </div>

  <!-- MODAL DOCUMENTO DE PAGO -->
  <div class="row">

    <modal #modalPago>
      <modal-content>
        <h4 style="text-align: center;"><b>¿Cómo realizar un Documento de Pago?</b></h4>
        <div class="row d-flex justify-content-center">
          <img class="petImg" src="../../../assets/Guia/facturin3.png" alt="">
        </div>
        <div class="row d-flex justify-content-center">

          <p>
            Para poder realizar un <b>Documento de Pago</b> accederemos al menú de ventas
            y enseguida en el submenú seleccionaremos <b> Documento de Pago</b>.
          </p>

          <img src="../assets/Guia/Facturacion/documentoPago/docPago1.png" alt="">
          <div class="row"><br></div>

          <p>
            Este Menú está separado en 3 pasos<b> Datos Generales, Documentos y
              Listo!</b>
          </p>

          <img src="../assets/Guia/Facturacion/documentoPago/docPago2.png" alt="">
          <div class="row"><br></div>

          <hr>
          <p>
            <b>1. Datos Generales</b><br><br>
            En este menú se deberán llenar los campos con la información del receptor de la factura.
            <br>
          </p>

          <p>Al introducir el cliente en el campo <b>"Facturar A"</b> se llenarán automáticamente los campos
            <b>Nombre, *RFC, Método de Pago, Forma de Pago</b>.
          </p>

          <small style="text-align: center !important; color: gray;">Se deberá haber creado un cliente previamente
            <br><br></small>

          <img src="../assets/Guia/Facturacion/documentoPago/docPago3.png" alt="">

          <p>
            <br>En este tipo de documento existen nuevos campos a tomar en cuenta, como son:
            <br><br>
            <b>Fecha de Pago</b>: expresa la fecha y hora en la que el beneficiario recibe el pago.
            <br><br>
            <b>No. Operación</b>: expresa el número de cheque, número de autorización, número de referencia, clave de
            rastreo en caso de ser SPEI, línea de captura o algún número de referencia análogo que identifique la
            operación que ampara el pago efectuado
            <br><br>
            <b>RFC Ordenante</b>: expresa la clave RFC de la entidad emisora de la cuenta origen, es decir, la
            operadora, el banco, la institución financiera, emisor de monedero electrónico, etc.
            <br><br>
            <b>Cta. Ordenante</b>: incorpora el número de la cuenta con la que se realizó el pago.
            <br><br>
            <b>RFC Beneficiario</b>:expresar la clave RFC de la entidad operadora de la cuenta destino, es decir, la
            operadora, el banco, la institución financiera, emisor de monedero electrónico, etc.
            <br><br>
            <b>Cta. Beneficiario</b>: incorpora el número de cuenta en donde se recibió el pago.
            <br><br>
          </p>

          <img src="../assets/Guia/Facturacion/documentoPago/docPago4.png" alt="">
          <div class="row"><br></div>

          <p>
            <b>Observaciones</b> es un apartado opcional para incluir las observaciones generales a la factura,
            información
            adicional al PDF
            que no es necesaria para el Timbrado.
          </p>

          <img src="../assets/Guia/Facturacion/facturaCFDIT/observaciones.png" alt="">
          <div class="row"><br></div>

          <hr>
          <p>
            <b>2. Documentos</b><br><br>En esta sección se seleccionará el documento XML relacionado al pago y se
            verificarán los datos del mismo.
          </p>

          <p>
            Para cargar el documento relacionado se deberá dar click en "Elegir Archivo", se desplegará una ventana
            donde se deberá seleccionar el documento correcto <b><u>(El documento seleccionado debe coincidir con el
                cliente seleccionado en "Facturar A" del paso 1. Datos generales)</u></b>
          </p>

          <p>
            Una vez seleccionado el archivo se debe dar click en el botón <b>"Cargar Documento"</b>, una vez cargado se
            mostrará en la previsualización el contenido del documento.
          </p>

          <img src="../assets/Guia/Facturacion/documentoPago/docPago5.png" alt="">
          <div class="row"><br></div>

          <p>
            También se puede buscar el documento entre los realizados previamente en la plataforma, dando click en
            <b>"Buscar por Serie"</b>.
          </p>

          <img src="../assets/Guia/Facturacion/documentoPago/docPago6.png" alt="">

          <div class="row"><br></div>

          <p>
            Se desplegará un pequeño menú de búsqueda en el que se podrá introducir <b>Folio</b> y <b>Serie</b> de
            documentos previamente timbrados en la plataforma, una vez llenados los cambos dar click en el botón <i
              class="fa fa-search" aria-hidden="true"></i>.
          </p>

          <img src="../assets/Guia/Facturacion/documentoPago/docPago9.png" alt="">
          <div class="row"><br></div>

          <p>
            Al cargar exitosamente el documento relacionado al pago, se mostrará el contenido reñevamte en la sección de
            previsualización.
            <br>
          </p>

          <img src="../assets/Guia/Facturacion/documentoPago/docPago7.png" alt="">
          <div class="row"><br></div>

          <p>
            Los campos <b> Número de Parcialidad, Importe Saldo Anterior, Importe Pagado, Importe Insoluto</b> son
            editables para indicar los importes correctos.
          </p>

          <img src="../assets/Guia/Facturacion/documentoPago/docPago8.png" alt="">
          <div class="row"><br></div>

          <p>
            Al llenar y comprobar que todos los campos estén correctos solo queda dar click al botón <b>"Guardar"</b>. y
            se visualizará el documento en la tabla inferior.
          </p>

          <img src="../assets/Guia/Facturacion/documentoPago/docPago10.png" alt="">
          <div class="row"><br></div>

          <hr>
          <p>
            <b>3. Listo!</b><br><br>
            En este paso solo queda dar click en el botón <b>"TIMBRAR"</b>, el sistema verificará los
            datos y
            se
            desplegará un mensaje de confirmación, o error en el caso de que algo esté mal.
          </p>

          <img src="../assets/Guia/Facturacion/facturaEstandar/facturaEstandar8.png" alt="">

          <div class="row"><br></div>

          <p>
            El mensaje de confirmación tendrá tres opciones disponibles
            <b>"Sí, Timbrar"</b>,
            <b>"Previsualizar"</b> y
            <b>"Cancelar"</b>.
          </p>

          <img style=" width: 80% !important;" src="../assets/Guia/Facturacion/facturaEstandar/facturaEstandar9.png"
            alt="">

          <div class="row"><br></div>

          <p style=" text-align: center;">

            <b style="color: rgb(42, 93, 233);">"Sí,
              Timbrar: "</b><br> Timbrará la factura. <br><br>

            <b style="color: green">"Previsualizar: "</b><br>Abrirá una nueva ventana en la cual se podrá previsualizar
            el
            PDF de la factura.<br><br>

            <b style="color: rgb(226, 19, 19);">"Cancelar: "</b><br> Regresará al menú anterior al timbrado.
          </p>
        </div>

        <hr>

        <div class="row">
          <div class="col d-flex justify-content-center">
            <button class="btn btn-danger" (click)="closeModalPago();openModal2()"><i class="fa fa-arrow-left"
                aria-hidden="true"></i>&nbsp;&nbsp;Regresar</button>
          </div>

        </div>

      </modal-content>
    </modal>
  </div>

</div>