import { Component, ViewChild } from '@angular/core';
import { DataService } from './login/data.service';
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router'
import { ModalManager } from 'ngb-modal';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'FNETSYSTEMS';
  loading = true;

  flagMenu: boolean;
  flagvalue = sessionStorage.getItem('correo');

  flagPrueba: boolean = false;
  showprueba: any;


  // Generales
  @ViewChild('myModal2') myModal2: any;
  @ViewChild('modalData') modalData: any;
  @ViewChild('modalCer') modalCer: any;
  @ViewChild('modalRelacion') modalRelacion: any;
  @ViewChild('cargaXML') cargaXML: any;
  @ViewChild('consultaCFDI') consultaCFDI: any;
  @ViewChild('cancelaCFDI') cancelaCFDI: any;

  // Facturacion
  @ViewChild('modalFStandard') modalFStandard: any;
  @ViewChild('modalCFDIT') modalCFDIT: any;
  @ViewChild('modalTransporte') modalTransporte: any;
  @ViewChild('modalPago') modalPago: any;

  // ACCORDION DE RELACION DE FACTURAS
  items = ['Guías Generales'];
  items2 = ['Timbrado de Facturas'];
  expandedIndex = 0;

  constructor(private router: Router, private data: DataService, private modalService: ModalManager) {

    this.router.events.subscribe((e: RouterEvent) => {
      this.navigationInterceptor(e);
    })

  }

  ngOnInit() {

    this.data.currentMenu.subscribe(flag_menu => this.flagMenu = flag_menu);

    if (this.flagvalue == undefined || this.flagvalue == '') {
      this.flagMenu = false;
    } else {
      this.flagMenu = true
    }


    // MODO PRUEBAS
    this.showprueba = sessionStorage.getItem('prueba')
    this.check_pruebas();
    this.data.currentPrueba.subscribe(flag_prueba => this.flagPrueba = flag_prueba);
  }

  check_pruebas() {
    if (this.showprueba == '' || this.showprueba == undefined || this.showprueba == 'N' || this.showprueba == 'null') {
      this.data.changePrueba(false)
    } else if (this.showprueba == 'S') {
      this.data.changePrueba(true)
    }
  }



  navigationInterceptor(event: RouterEvent): void {

    if (event instanceof NavigationStart) {
      this.loading = true
    }
    if (event instanceof NavigationEnd) {
      this.loading = false
    }
    if (event instanceof NavigationCancel) {
      this.loading = false
    }
    if (event instanceof NavigationError) {
      this.loading = false
    }

  }


  // ---------------------------------------- MODALES----------------------------------------


  private modalRef: any;

  // Modal de revision de estado
  openModal2() {

    this.modalRef = this.modalService.open(this.myModal2, {
      windowClass: 'custom-class',
      size: "md",
      modalClass: 'mymodal2',
      hideCloseButton: true,
      centered: true,
      // backdrop: true,
      animation: true,
      scrollable: true,
      closeOnOutsideClick: true,
    })
  }

  //Cierra el modal activo
  closeModal() {
    this.modalService.close(this.modalRef);
  }




  private modalRef2: any;

  //------------------  MODAL DATOS PERSONALES ------------------

  openModalData() {

    this.modalRef2 = this.modalService.open(this.modalData, {
      windowClass: 'custom-class',
      size: "md",
      modalClass: 'modalData',
      hideCloseButton: true,
      centered: true,
      // backdrop: true,
      animation: true,
      scrollable: true,
      closeOnOutsideClick: true,
    })
  }

  //Cierra el modal activo
  closeModalData() {
    this.modalService.close(this.modalRef2);
  }

  //------------------  MODAL FACTURA CERTIFICADO ------------------

  private modalRef3: any;

  openModalCer() {
    this.modalRef3 = this.modalService.open(this.modalCer, {
      windowClass: 'custom-class',
      size: "md",
      modalClass: 'modalCer',
      hideCloseButton: true,
      centered: true,
      // backdrop: true,
      animation: true,
      scrollable: true,
      closeOnOutsideClick: true,
    })
  }

  //Cierra el modal activo
  closeModalCer() {
    this.modalService.close(this.modalRef3);
  }


  //------------------  MODAL FACTURA ESTANDAR ------------------

  private modalRef5: any;

  openModalFStandard() {
    this.modalRef5 = this.modalService.open(this.modalFStandard, {
      windowClass: 'custom-class',
      size: "md",
      modalClass: 'modalFStandard',
      hideCloseButton: true,
      centered: true,
      // backdrop: true,
      animation: true,
      scrollable: true,
      closeOnOutsideClick: true,
    })
  }

  //Cierra el modal activo
  closeModalFStandard() {
    this.modalService.close(this.modalRef5);
  }



  //------------------  MODAL FACTURA CFDI TRASLADO ------------------

  // Modal Certificado
  private modalRef4: any;

  openModalCFDIT() {
    this.modalRef4 = this.modalService.open(this.modalCFDIT, {
      windowClass: 'custom-class',
      size: "md",
      modalClass: 'modalCFDIT',
      hideCloseButton: true,
      centered: true,
      // backdrop: true,
      animation: true,
      scrollable: true,
      closeOnOutsideClick: true,
    })
  }

  closeModalCFDIT() {
    this.modalService.close(this.modalRef4);
  }



  //------------------  MODAL FACTURA TRANSPORTE ------------------

  // Modal Certificado
  private modalRef6: any;

  openModalTransporte() {
    this.modalRef6 = this.modalService.open(this.modalTransporte, {
      windowClass: 'custom-class',
      size: "md",
      modalClass: 'modalTransporte',
      hideCloseButton: true,
      centered: true,
      // backdrop: true,
      animation: true,
      scrollable: true,
      closeOnOutsideClick: true,
    })
  }

  closeModalTransporte() {
    this.modalService.close(this.modalRef6);
  }



  //------------------  MODAL FACTURA TRANSPORTE ------------------

  // Modal Certificado
  private modalRef7: any;

  openModalRelacion() {
    this.modalRef7 = this.modalService.open(this.modalRelacion, {
      windowClass: 'custom-class',
      size: "md",
      modalClass: 'modalRelacion',
      hideCloseButton: true,
      centered: true,
      // backdrop: true,
      animation: true,
      scrollable: true,
      closeOnOutsideClick: true,
    })
  }

  closeModalRelacion() {
    this.modalService.close(this.modalRef7);
  }


  //------------------  MODAL FACTURA TRANSPORTE ------------------

  // Modal Certificado
  private modalRef8: any;

  openModalcargaXML() {
    this.modalRef8 = this.modalService.open(this.cargaXML, {
      windowClass: 'custom-class',
      size: "md",
      modalClass: 'cargaXML',
      hideCloseButton: true,
      centered: true,
      // backdrop: true,
      animation: true,
      scrollable: true,
      closeOnOutsideClick: true,
    })
  }

  closeModalcargaXML() {
    this.modalService.close(this.modalRef8);
  }


  // Modal Consulta de CFDI
  private modalRef9: any;

  openModalconsultaCFDI() {
    this.modalRef9 = this.modalService.open(this.consultaCFDI, {
      windowClass: 'custom-class',
      size: "md",
      modalClass: 'cargaXML',
      hideCloseButton: true,
      centered: true,
      // backdrop: true,
      animation: true,
      scrollable: true,
      closeOnOutsideClick: true,
    })
  }

  closeModalconsultaCFDI() {
    this.modalService.close(this.modalRef9);
  }


  // Modal Consulta de CFDI
  private modalRef10: any;

  openModalcancelaCFDI() {
    this.modalRef10 = this.modalService.open(this.cancelaCFDI, {
      windowClass: 'custom-class',
      size: "md",
      modalClass: 'cancelaCFDI',
      hideCloseButton: true,
      centered: true,
      // backdrop: true,
      animation: true,
      scrollable: true,
      closeOnOutsideClick: true,
    })
  }

  closeModalcancelaCFDI() {
    this.modalService.close(this.modalRef10);
  }



  // Modal Documento de Pago
  private modalRef11: any;

  openModalPago() {
    this.modalRef11 = this.modalService.open(this.modalPago, {
      windowClass: 'custom-class',
      size: "md",
      modalClass: 'modalPago',
      hideCloseButton: true,
      centered: true,
      // backdrop: true,
      animation: true,
      scrollable: true,
      closeOnOutsideClick: true,
    })
  }

  closeModalPago() {
    this.modalService.close(this.modalRef11);
  }




}