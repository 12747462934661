import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CatalogosService } from '../../catalogos.service';
import Swal from 'sweetalert2';
import { ModalManager } from 'ngb-modal';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private _router: Router, private servicio: CatalogosService, private modalService: ModalManager) { }

  @ViewChild('modalCer') modalCer: any;
  // --------------------------------- OBJETOS Y VARIABLES ---------------------------------

  expandedIndex = 0;

  nombre = '';
  rfc;
  flagDoc = 0;
  flag = 1;
  aviso = 'No existen avisos recientes'
  avisoDoc = ''
  items = ''
  arrayAvisos: any = [];
  resarrayAvisos: any = [];


  modo = '';

  // Datos Personales
  nombre_key;
  nombre_cer;
  certificado;
  noCertificado;
  llave;
  prueba_msg = false;
  ngbefor
  ancho = 0;
  botones = 0;
  baseRel = sessionStorage.getItem("baseRelacionada")
  modo_prueba: any;

  // --------------------------------- FUNCIONES ---------------------------------


  ngOnInit() {

    this.get_avisos();
    this.resolucion();

    this.nombre = sessionStorage.getItem("empresa");
    this.rfc = sessionStorage.getItem("rfc");

    if (this.nombre != null && this.rfc != null) {
     
    } else if (this.nombre == '') {
      this.flagDoc = 1;
      this.avisoDoc = 'Datos personales faltantes';
    }


    // Datos Personales
    this.nombre_key = sessionStorage.getItem('nombre_key');
    this.nombre_cer = sessionStorage.getItem('nombre_cer');
    this.certificado = sessionStorage.getItem('certificado');
    this.noCertificado = sessionStorage.getItem('noCertificado');
    this.llave = sessionStorage.getItem('llave');

    // Función que checa sus datos personales y avisa si no estan completos
    this.check_data();


    if (sessionStorage.getItem("key") == null) {
      this._router.navigate(['./login']);
    } else {
    }
  }


  // Funcion para el responsive
  resolucion() {
    this.ancho = window.innerWidth;
    console.log("Ancho de Página: ", this.ancho);
    if (this.ancho > 1400) {
      this.botones = 1;
    } else if (this.ancho <= 1400 && this.ancho > 600) {
      this.botones = 2;
    } else if (this.ancho <= 600) {
      this.botones = 3;
    }
  }


  // ------------------- VERIFICACION DE DATOS PRE TIMBRADO -------------------

  obj_user = {

    // DATOS CERTIFICADO
    certificado: '',
    NoCertificado: '',
    nombre_cer: '',
    cliente_key: '',

    // EMPRESA
    empresa: '',
    rfc: '',
    calle: '',
    cp: '',
    regimenFiscal: '',
  }


  checkData() {

    var flagCert = false;
    var flagData = false;

    // DATOS CERTIFICADO
    this.obj_user.certificado = sessionStorage.getItem('certificado')
    this.obj_user.NoCertificado = sessionStorage.getItem('noCertificado')
    this.obj_user.nombre_cer = sessionStorage.getItem('nombre_cer')
    this.obj_user.cliente_key = sessionStorage.getItem('cliente_key')

    // EMPRESA
    this.obj_user.empresa = sessionStorage.getItem('empresa')
    this.obj_user.rfc = sessionStorage.getItem('rfc')
    this.obj_user.calle = sessionStorage.getItem('calle')
    this.obj_user.cp = sessionStorage.getItem('cp')
    this.obj_user.regimenFiscal = sessionStorage.getItem('regimen')

    if(this.obj_user.empresa == null){
      this.obj_user.empresa = ''
    }
    if(this.obj_user.rfc == null){
      this.obj_user.rfc = ''
    }
    if(this.obj_user.calle == null){
      this.obj_user.calle = ''
    }
    if(this.obj_user.cp == null){
      this.obj_user.cp = ''
    }
    if(this.obj_user.regimenFiscal == null){
      this.obj_user.regimenFiscal = ''
    }

    // CHECKS DE DATOS PERSONALES 
    if (this.obj_user.certificado == '' || this.obj_user.certificado == null ||
      this.obj_user.NoCertificado == '' || this.obj_user.NoCertificado == null ||
      this.obj_user.nombre_cer == '' || this.obj_user.nombre_cer == null
    ) {
      console.log(this.obj_user);
      Swal.fire({
        icon: "warning",
        title: "¡Advertencia!",
        text: "No existe un certificado activo, la aplicación puede no funcionar correctamente",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cargar Certificado",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          this._router.navigate(['/datos/userdata'])
        }
      });
    } else {
      flagCert = true;
    }


    // CHECK DE CERTIFICADO
    if (this.obj_user.empresa == '' || this.obj_user.empresa == null ||
      this.obj_user.rfc == '' || this.obj_user.rfc == null ||
      this.obj_user.calle == '' || this.obj_user.calle == null ||
      this.obj_user.cp == '' || this.obj_user.cp == null ||
      this.obj_user.regimenFiscal == '' || this.obj_user.regimenFiscal == null
    ) {
      console.log(this.obj_user);

      Swal.fire({
        icon: "warning",
        title: "¡Advertencia!",
        html: `<p>Datos personales inválidos, la aplicación puede no funcionar correctamente</p>
          <br>
  
          <p style="${this.obj_user.empresa == null || this.obj_user.empresa == '' ? 'color:#dc3545' : 'color:green'}"><b>Nombre:</b> ${this.obj_user.empresa}
           <i style="${this.obj_user.empresa == null || this.obj_user.empresa == '' ? 'display:none' : 'display:inline'}" class="fa fa-check-circle-o" aria-hidden="true"></i>
           <i style="${this.obj_user.empresa == null || this.obj_user.empresa == '' ? 'display:inline' : 'display:none'}" class="fa fa-times" aria-hidden="true"></i></p>
  
          <p style="${this.obj_user.rfc == null || this.obj_user.rfc == '' ? 'color:#dc3545' : 'color:green'}"><b>RFC: </b>${this.obj_user.rfc}
          <i style="${this.obj_user.rfc == null || this.obj_user.rfc == '' ? 'display:none' : 'display:inline'}" class="fa fa-check-circle-o" aria-hidden="true"></i>
          <i style="${this.obj_user.rfc == null || this.obj_user.rfc == '' ? 'display:inline' : 'display:none'}" class="fa fa-times" aria-hidden="true"></i></p>
  
          <p style="${this.obj_user.calle == null || this.obj_user.calle == '' ? 'color:#dc3545' : 'color:green'}"><b>Calle: </b>${this.obj_user.calle}
          <i style="${this.obj_user.calle == null || this.obj_user.calle == '' ? 'display:none' : 'display:inline'}" class="fa fa-check-circle-o" aria-hidden="true"></i>
          <i style="${this.obj_user.calle == null || this.obj_user.calle == '' ? 'display:inline' : 'display:none'}" class="fa fa-times" aria-hidden="true"></i></p>
          
  
          <p style="${this.obj_user.cp == null || this.obj_user.cp == '' ? 'color:#dc3545' : 'color:green'}"><b>C.P.: </b>${this.obj_user.cp}
          <i style="${this.obj_user.cp == null || this.obj_user.cp == '' ? 'display:none' : 'display:inline'}" class="fa fa-check-circle-o" aria-hidden="true"></i>
          <i style="${this.obj_user.cp == null || this.obj_user.cp == '' ? 'display:inline' : 'display:none'}" class="fa fa-times" aria-hidden="true"></i></p>
         
         
          <p style="${this.obj_user.regimenFiscal == null || this.obj_user.regimenFiscal == '' ? 'color:#dc3545' : 'color:green'}"><b>Régimen Fiscal: </b>${this.obj_user.regimenFiscal}
          <i style="${this.obj_user.regimenFiscal == null || this.obj_user.regimenFiscal == '' ? 'display:none' : 'display:inline'}" class="fa fa-check-circle-o" aria-hidden="true"></i>
          <i style="${this.obj_user.regimenFiscal == null || this.obj_user.regimenFiscal == '' ? 'display:inline' : 'display:none'}" class="fa fa-times" aria-hidden="true"></i></p>
          `,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Verificar Datos",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this._router.navigate(['/datos/userdata'])
        }
      });
    } else {
      flagData = true;
    }

    console.log(flagData, flagCert);

    if (flagCert && flagData) {
      this._router.navigate(['/ventas/factura'])
    }

  }

  // ------------------------------------------------------------------------

  avisosFiltered: any = [];
  // Funcion que trae los avisos del manager
  get_avisos() {
    var obj = {
      baseRel: this.baseRel
    }
    this.servicio.select_avisos(obj).subscribe(res => {
      this.resarrayAvisos = res;
      this.avisosFiltered = this.resarrayAvisos.data[0]


      console.log(this.avisosFiltered);


      for (let a of this.avisosFiltered) {
        if (a.categoria == 'U') {
          this.flag = 1
          this.aviso = 'Existen avisos Urgentes recientes'
        } else {
          this.flag = 2
        }

        if (this.avisosFiltered.length > 0 && this.flag != 1) {
          this.aviso = 'Existen avisos recientes'
        }
      }




    })


  }

  reloadCurrentRoute() {
    const currentUrl = this._router.url;
    this._router.navigateByUrl('/',).then(() => {
      this._router.navigate([currentUrl]);
    });
  }

  check_data() {
    if (this.nombre_key == '' || this.nombre_key == undefined) {
      this.flagDoc = 1;
      this.avisoDoc = 'Archivos (.key) faltantes, verifica tus datos personales';
    } else if (
      this.nombre_cer == '' ||
      this.nombre_cer == undefined ||
      this.certificado == '' ||
      this.certificado == undefined ||
      this.noCertificado == '' ||
      this.noCertificado == undefined
    ) {
      this.flagDoc = 1;
      this.avisoDoc = 'Archivos (.cer) faltantes, verifica tus datos personales';
    } else if (this.llave == '' || this.llave == undefined) {
      this.flagDoc = 1;
      this.avisoDoc = 'Archivos (.key) faltantes, verifica tus datos personales';
    }

  }

  color(datos) {

    if (datos.categoria == 'P') {
      return 'personal'
    } else if (datos.categoria == 'U') {
      return 'urgente'
    } else if (datos.categoria == 'G') {
      return 'general'
    } else {
      return 'general'
    }

  }




  private modalRef3: any;

  // Modal Certificado
  openModalCer() {
    this.modalRef3 = this.modalService.open(this.modalCer, {
      windowClass: 'custom-class',
      size: "md",
      modalClass: 'modalCer',
      hideCloseButton: true,
      centered: true,
      // backdrop: true,
      animation: true,
      scrollable: true,
      closeOnOutsideClick: true,
    })
  }

  //Cierra el modal activo
  closeModalCer() {
    this.modalService.close(this.modalRef3);
  }

}
